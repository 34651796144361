import {
    FunctionComponent,
    MouseEvent,
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Badge,
    Button,
    Divider,
    Icon,
    Menu,
    Tab,
    Tabs,
    styled,
} from "@mui/material";

import { useInstantSearch } from "react-instantsearch";
import { useSearchParams } from "react-router-dom";

import {
    FacetMenuItem,
    IRangeChangeEvent,
    IRangeDateChangeEvent,
    ISortChangeEvent,
    ISortItem,
    ITagChangeEvent,
    RangeDateInput,
    RangeInput,
    SearchBox,
    SortMenu,
    TagInput,
} from "../../components/common/typesense";
import { useListAllLinkViews } from "../../sdk/hooks";
import { ILinkView, LinkViewSortAttribute } from "../../sdk/types";
import { ILinkViewChangeEvent, LinkViewUtils } from "../../sdk/utils";

import { LinkViewFooter } from "./LinkViewFooter";
import { LINKS_COLLECTION } from "./config/typesense.config.adapter";

const FlexContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Root = styled("div")``;

const Header = styled(FlexContainer)`
    justify-content: space-between;

    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledTabs = styled(Tabs)`
    flex: 1;

    position: relative;
    right: ${({ theme }) => theme.spacing(2)};

    .Mui-selected {
        background-color: #dedede;

        text-transform: none;

        border-radius: ${({ theme }) => theme.spacing(0.5, 0.5, 0, 0)};
    }
`;

const StyledTab = styled(Tab)`
    text-transform: none;
`;

const EditMark = styled(Badge)`
    & .MuiBadge-badge {
        position: relative;
        right: 10px;
    }
`;

const FilterActionsContainer = styled(FlexContainer)`
    justify-content: space-evenly;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const filterOptions = [
    {
        id: "1",
        value: "createdAt",
        label: "CreatedAt",
    },
    {
        id: "2",
        value: "clicks",
        label: "Clicks",
    },
    {
        id: "3",
        value: "scans",
        label: "Scans",
    },
    {
        id: "4",
        value: "tags",
        label: "Tags",
    },
];

const sortOptions: ISortItem[] = [
    {
        attribute: "alias",
        label: "Alias",
    },
    {
        attribute: "createdAt",
        label: "CreatedAt",
    },
    {
        attribute: "clicks",
        label: "Clicks",
    },
    {
        attribute: "scans",
        label: "Scans",
    },
];

export const LinkView: FunctionComponent = (): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const linkViewsQuery = useListAllLinkViews({
        params: {
            limit: 100,
        },
        options: {
            queryKey: ["link-views"],
            onSuccess: (data) => {
                const filters = data.records;
                filters.sort(
                    (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
                );
                setLinkViews(data.records);

                const filterId = searchParams.get("filter");
                const filterIndex = filters.findIndex(
                    (filter) => filter.id === filterId,
                );
                setCurrentLinkViewTabIndex(filterIndex >= 0 ? filterIndex : 0);
            },
        },
    });

    const [linkViews, setLinkViews] = useState<ILinkView[]>([]);
    const [editedLinkViewIds, setEditedLinkViewIds] = useState<Set<String>>(
        new Set(),
    );

    const [currentLinkViewTabIndex, setCurrentLinkViewTabIndex] =
        useState<number>(0);
    const [filterButtonElement, setFilterButtonElement] =
        useState<null | HTMLElement>(null);
    const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
    const [selectedFilterOption, setSelectedFilterOption] = useState<
        string | null
    >(null);

    const { setIndexUiState } = useInstantSearch();

    const handleLinkViewTabChange = useCallback(
        (_event: SyntheticEvent, index: number) => {
            setCurrentLinkViewTabIndex(index);
            setSearchParams((params) => ({
                ...params,
                filter: linkViews[index].id,
            }));
        },
        [linkViews],
    );

    const handleFilterMenuOpen = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            setFilterButtonElement(event.currentTarget);
            setShowFilterMenu(true);
        },
        [],
    );

    const handleFilterMenuClose = useCallback(() => {
        setShowFilterMenu(false);
    }, []);

    const handleFilterOptionChange = useCallback(
        (_event: MouseEvent<HTMLLIElement>, value: string) => {
            setSelectedFilterOption(value);
        },
        [],
    );

    const handleFilterDialogClose = useCallback(() => {
        setSelectedFilterOption(null);
    }, []);

    const handleLinkViewEdit = useCallback((id: string) => {
        setEditedLinkViewIds((viewsIds) => new Set(viewsIds.add(id)));
    }, []);

    const handleLinkViewChange = useCallback(
        (params: ILinkViewChangeEvent) => {
            const { id, value, event } = params;
            /* Remove the current view ID from the set of edited IDs */
            setEditedLinkViewIds((viewsIds) => {
                viewsIds.delete(id);
                return new Set(viewsIds);
            });
            /* Find the original view for potential restoration */
            const originalView = linkViewsQuery.data?.records.find(
                (linkView) => linkView.id === id,
            );
            switch (event) {
                case "delete": {
                    /* Remove the view with the specified ID and reset the tab index */
                    setLinkViews((views) =>
                        views.filter((view) => view.id !== id),
                    );
                    setCurrentLinkViewTabIndex(0);
                    break;
                }
                case "create": {
                    /* Add the new view to the list and set the tab index to the new view */
                    setLinkViews((views) => [...views, value]);
                    setCurrentLinkViewTabIndex(linkViews.length);
                    break;
                }
                case "update": {
                    /* Update the specified view with the new value */
                    setLinkViews((views) =>
                        views.map((view) => (view.id === id ? value : view)),
                    );
                    break;
                }
                case "reset": {
                    /* Restore the view to its original state if it exists */
                    setLinkViews((views) =>
                        views.map((view) =>
                            view.id === id && originalView
                                ? originalView
                                : view,
                        ),
                    );
                    break;
                }
                default: {
                    /* Handle any other events by marking the view as edited and updating it */
                    setEditedLinkViewIds(
                        (viewsIds) => new Set(viewsIds.add(id)),
                    );
                    setLinkViews((views) =>
                        views.map((view) => (view.id === id ? value : view)),
                    );
                    break;
                }
            }
        },
        [linkViews, linkViewsQuery],
    );

    const handleRangeInputChange = useCallback(
        (params: IRangeChangeEvent) => {
            const { id, value, attribute } = params;
            handleLinkViewEdit(id);
            setLinkViews((views) =>
                views.map((view) =>
                    view.id === id
                        ? LinkViewUtils.updateView({ view, attribute, value })
                        : view,
                ),
            );
        },
        [handleLinkViewEdit],
    );
    const handleRangeDateInputChange = useCallback(
        (params: IRangeDateChangeEvent) => {
            const { id, value, attribute } = params;
            handleLinkViewEdit(id);
            setLinkViews((views) =>
                views.map((view) =>
                    view.id === id
                        ? LinkViewUtils.updateView({ view, attribute, value })
                        : view,
                ),
            );
        },
        [handleLinkViewEdit],
    );
    const handleTagInputChange = useCallback(
        (params: ITagChangeEvent) => {
            const { id, value, attribute } = params;
            handleLinkViewEdit(id);
            setLinkViews((views) =>
                views.map((view) =>
                    view.id === id
                        ? LinkViewUtils.updateView({ view, attribute, value })
                        : view,
                ),
            );
        },
        [handleLinkViewEdit],
    );
    const handleSortChange = useCallback(
        (params: ISortChangeEvent) => {
            const { id, value } = params;
            handleLinkViewEdit(id);
            setLinkViews((views) =>
                views.map((view) =>
                    view.id === id
                        ? LinkViewUtils.updateView({
                              view,
                              attribute: "sort",
                              value: {
                                  ...value,
                                  attribute: LinkViewUtils.getSortAttributeEnum(
                                      value.attribute ?? null,
                                  ),
                              },
                          })
                        : view,
                ),
            );
        },
        [handleLinkViewEdit],
    );

    const renderTab = useCallback(
        (tab: ILinkView, index: number) => {
            if (editedLinkViewIds.has(tab.id)) {
                return (
                    <StyledTab
                        label={
                            <EditMark badgeContent={"*"}>{tab.label}</EditMark>
                        }
                        value={index}
                        key={tab.id}
                    />
                );
            }
            return <StyledTab label={tab.label} value={index} key={tab.id} />;
        },
        [editedLinkViewIds],
    );

    const currentLinkView = useMemo(
        () => linkViews.at(currentLinkViewTabIndex),
        [
            JSON.stringify(linkViews.at(currentLinkViewTabIndex)),
            currentLinkViewTabIndex,
        ],
    );

    useEffect(() => {
        if (currentLinkView) {
            /* Sync the view state with typesense ui state. */
            setIndexUiState(
                LinkViewUtils.getTypesenseUiState({
                    view: currentLinkView,
                }),
            );
        }
    }, [JSON.stringify(currentLinkView)]);

    return (
        <Root>
            <Divider />
            <Header>
                <StyledTabs
                    value={currentLinkViewTabIndex}
                    onChange={handleLinkViewTabChange}
                    scrollButtons={true}
                    variant="scrollable"
                >
                    {linkViews.map(renderTab)}
                </StyledTabs>
                <FilterActionsContainer>
                    <RangeInput
                        attribute="clicks"
                        label="Clicks"
                        exactValue={
                            currentLinkView?.filters.clicks.exactValue ?? null
                        }
                        range={{
                            min:
                                currentLinkView?.filters.clicks.range.min ??
                                null,
                            max:
                                currentLinkView?.filters.clicks.range.max ??
                                null,
                        }}
                        mode={currentLinkView?.filters.clicks.mode ?? null}
                        open={selectedFilterOption === "clicks"}
                        id={currentLinkView?.id ?? ""}
                        handleChange={handleRangeInputChange}
                        handleClose={handleFilterDialogClose}
                        anchorElement={filterButtonElement as Element}
                    />
                    <RangeInput
                        attribute="scans"
                        label="Scans"
                        exactValue={
                            currentLinkView?.filters.scans.exactValue ?? null
                        }
                        range={{
                            min:
                                currentLinkView?.filters.scans.range.min ??
                                null,
                            max:
                                currentLinkView?.filters.scans.range.max ??
                                null,
                        }}
                        mode={currentLinkView?.filters.scans.mode ?? null}
                        open={selectedFilterOption === "scans"}
                        id={currentLinkView?.id ?? ""}
                        handleChange={handleRangeInputChange}
                        handleClose={handleFilterDialogClose}
                        anchorElement={filterButtonElement as Element}
                    />
                    <RangeDateInput
                        attribute="createdAt"
                        label="CreatedAt"
                        minDate={
                            currentLinkView?.filters.createdAt.minDate ?? null
                        }
                        maxDate={
                            currentLinkView?.filters.createdAt.maxDate ?? null
                        }
                        open={selectedFilterOption === "createdAt"}
                        id={currentLinkView?.id ?? ""}
                        handleChange={handleRangeDateInputChange}
                        handleClose={handleFilterDialogClose}
                        anchorElement={filterButtonElement as Element}
                    />
                    <TagInput
                        id={currentLinkView?.id ?? ""}
                        attribute="tags"
                        label="Tags"
                        open={selectedFilterOption === "tags"}
                        tags={currentLinkView?.filters.tags ?? []}
                        onChange={handleTagInputChange}
                        onClose={handleFilterDialogClose}
                        anchorEl={filterButtonElement as Element}
                        typesenseCollection={LINKS_COLLECTION}
                    />
                    <Button
                        variant="outlined"
                        startIcon={<Icon>filter_alt_outlined</Icon>}
                        size="small"
                        onClick={handleFilterMenuOpen}
                    >
                        Add Filter
                    </Button>
                    <Menu
                        open={showFilterMenu}
                        anchorEl={filterButtonElement}
                        onClose={handleFilterMenuClose}
                    >
                        {filterOptions.map((filter) => (
                            <FacetMenuItem
                                onClick={handleFilterOptionChange}
                                key={filter.id}
                                value={filter.value}
                                label={filter.label}
                            />
                        ))}
                    </Menu>
                    <SortMenu
                        options={sortOptions}
                        label="Sort"
                        buttonProps={{
                            size: "small",
                            variant: "outlined",
                            startIcon: <Icon>sort</Icon>,
                        }}
                        id={currentLinkView?.id ?? ""}
                        attribute={LinkViewUtils.getSortAttributeValue(
                            currentLinkView?.filters.sort.attribute ?? null,
                        )}
                        order={currentLinkView?.filters.sort.order ?? null}
                        onChange={handleSortChange}
                    />
                    <SearchBox />
                </FilterActionsContainer>
            </Header>
            <Divider />
            {currentLinkView && (
                <LinkViewFooter
                    filter={currentLinkView}
                    onChange={handleLinkViewChange}
                    id={currentLinkView.id}
                />
            )}
        </Root>
    );
};
