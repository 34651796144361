import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Button,
    Icon,
    IconButton,
    Popover,
    Typography,
    styled,
} from "@mui/material";

import { InstantSearch, useRefinementList } from "react-instantsearch";

import { typesenseAdapter } from "../../../screens/view-links/config/typesense.config.adapter";

import { TagInputSearchBox } from "./TagInputSearchBox";

const FlexContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: start;
`;

const Root = styled(FlexContainer)`
    width: 260px;

    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(2)};
`;

const Header = styled("div")`
    display: flex;
    justify-content: start;
    align-items: center;
`;

const Footer = styled(FlexContainer)`
    width: 100%;

    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Title = styled(Typography)``;

const BackButton = styled(IconButton)``;

export interface ITagChangeEvent {
    id: string;
    attribute: string;
    value: string[];
}

export interface ITagInputProps {
    id: string;
    label: string;
    attribute: string;
    open: boolean;
    tags: string[];
    onChange: (params: ITagChangeEvent) => void;
    onClose: () => void;
    anchorEl: Element;
    typesenseCollection: string;
}

export const TagInput: FunctionComponent<ITagInputProps> = (
    props: ITagInputProps,
): ReactElement => {
    const {
        id,
        label,
        attribute,
        open,
        tags,
        onChange,
        onClose,
        anchorEl,
        typesenseCollection,
    } = props;

    const [tagsState, setTagsState] = useState<string[]>(tags);

    /*
        The useInstantSearch hook requires either a mounted widget or a virtualized widget.
        Therefore, we use the useRefinementList hook to mount a virtual widget.
        Mounting a refinement list enables the use of the refinementList prop within setIndexUiState.
    */
    useRefinementList({
        attribute: attribute,
    });

    const handleTagsChange = useCallback((value: string[]) => {
        setTagsState(value);
    }, []);

    const handleSave = useCallback(() => {
        onChange({
            attribute,
            id,
            value: tagsState,
        });
        onClose();
    }, [tagsState, onChange, onClose, attribute, id]);

    const handleClose = useCallback(() => {
        setTagsState(tags);
        onClose();
    }, [tags, onClose]);

    if (!open) {
        return <div></div>;
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <Root>
                <Header>
                    <BackButton>
                        <Icon onClick={handleClose}>arrow_back</Icon>
                    </BackButton>
                    <Title>{label}</Title>
                </Header>
                <InstantSearch
                    indexName={typesenseCollection}
                    searchClient={typesenseAdapter.searchClient}
                >
                    <TagInputSearchBox
                        attribute={attribute}
                        onChange={handleTagsChange}
                        tags={tagsState}
                    />
                </InstantSearch>
                <Footer>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Footer>
            </Root>
        </Popover>
    );
};
