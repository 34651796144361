import { FunctionComponent, ReactElement, useCallback } from "react";

import { Checkbox, Icon, MenuItem, styled } from "@mui/material";

const MenuItemContainer = styled("div")`
    display: flex;
    justify-content: start;
    align-items: center;

    min-width: 150px;
`;

const StyledMenuItem = styled(MenuItem)`
    padding: ${({ theme }) => theme.spacing(1, 0)};
`;

export interface ISortItem {
    attribute: string;
    label: string;
}

export interface ISortMenuItemProps {
    item: ISortItem;
    checked: boolean;
    onToggle: (attribute: string) => void;
}

export const SortMenuItem: FunctionComponent<ISortMenuItemProps> = (
    props: ISortMenuItemProps,
): ReactElement => {
    const { item, checked, onToggle } = props;

    const handleToggle = useCallback(() => {
        onToggle(item.attribute);
    }, [onToggle]);

    return (
        <MenuItemContainer>
            <Checkbox
                size="small"
                icon={<Icon fontSize="small">radio_button_unchecked</Icon>}
                value={item.attribute}
                name={item.attribute}
                checkedIcon={<Icon>radio_button_checked</Icon>}
                checked={checked}
                onClick={handleToggle}
            />
            <StyledMenuItem value={item.attribute}>{item.label}</StyledMenuItem>
        </MenuItemContainer>
    );
};
