import {
    FunctionComponent,
    MouseEvent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Button,
    Icon,
    IconButton,
    Menu,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
} from "@mui/material";

import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { useRange } from "react-instantsearch";

const FlexContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: start;
`;

const Root = styled(FlexContainer)`
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(2)};
`;

const Header = styled("div")`
    display: flex;
    justify-content: start;
    align-items: center;
`;

const Body = styled("div")``;

const InputContainer = styled("div")``;

const SelectedDate = styled(PickersDay<Date>)`
    background-color: ${({ selected }) => (selected ? "#CBDEF0" : "white")};
`;

const Footer = styled(FlexContainer)`
    width: 100%;

    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Title = styled(Typography)``;

const BackButton = styled(IconButton)``;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};

    .Mui-selected {
        background-color: #cbdef0 !important;
    }
`;

const StyledToggleButton = styled(ToggleButton)`
    flex: 1;

    text-transform: none;
`;

export interface IRangeDateChangeEvent {
    id: string;
    attribute: string;
    value: {
        minDate: Date | null;
        maxDate: Date | null;
    };
}

export interface IRangeDateInputProps {
    id: string;
    attribute: string;
    label: string;
    minDate: Date | null;
    maxDate: Date | null;
    handleChange: (value: IRangeDateChangeEvent) => void;
    handleClose: () => void;
    open: boolean;
    anchorElement: Element;
}

export const RangeDateInput: FunctionComponent<IRangeDateInputProps> = (
    props: IRangeDateInputProps,
): ReactElement => {
    const {
        id,
        minDate,
        maxDate,
        attribute,
        label,
        handleChange,
        handleClose,
        open,
        anchorElement,
    } = props;

    const [currentAlignment, setCurrentAlignment] =
        useState<string>("start-date");
    const [startDate, setStartDate] = useState<Date | null>(minDate);
    const [endDate, setEndDate] = useState<Date | null>(maxDate);

    /*
        The useInstantSearch hook requires either a mounted widget or a virtualized widget.
        Therefore, we use the useRange hook to mount a virtual widget.
        Mounting a range widget enables the use of the range prop within setIndexUiState.
    */
    useRange({
        attribute,
    });

    const handleAlignmentChange = useCallback(
        (_event: MouseEvent<HTMLElement>, value: string) => {
            setCurrentAlignment(value);
        },
        [],
    );

    const handleStartDateChange = useCallback((value: Date | null) => {
        setStartDate(value);
    }, []);

    const handleStartEndChange = useCallback((value: Date | null) => {
        setEndDate(value);
    }, []);

    const renderDate = useCallback(
        (props: PickersDayProps<Date>): ReactElement => {
            const { day } = props;

            const selected =
                startDate && endDate
                    ? day.getTime() >= startDate.getTime() &&
                      day.getTime() <= endDate.getTime()
                    : startDate?.getTime() === day.getTime() ||
                      endDate?.getTime() === day.getTime();

            return <SelectedDate {...props} selected={selected} />;
        },
        [startDate, endDate],
    );

    const handleSave = useCallback(() => {
        handleChange({
            id,
            attribute,
            value: {
                minDate: startDate,
                maxDate: endDate,
            },
        });
        handleClose();
    }, [id, attribute, startDate, endDate, handleChange, handleClose]);

    if (!open) {
        return <div></div>;
    }

    return (
        <Menu open={open} anchorEl={anchorElement}>
            <Root>
                <Header>
                    <BackButton onClick={handleClose}>
                        <Icon>arrow_back</Icon>
                    </BackButton>
                    <Title>{label}</Title>
                </Header>
                <Body>
                    <StyledToggleButtonGroup
                        value={currentAlignment}
                        onChange={handleAlignmentChange}
                        exclusive={true}
                        size="small"
                    >
                        <StyledToggleButton value={"start-date"}>
                            Start Date
                        </StyledToggleButton>
                        <StyledToggleButton value={"end-date"}>
                            End Date
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                    <InputContainer>
                        {currentAlignment === "start-date" && (
                            <DateCalendar
                                value={startDate}
                                onChange={handleStartDateChange}
                                slots={{ day: renderDate }}
                                disableHighlightToday={true}
                            />
                        )}
                        {currentAlignment === "end-date" && (
                            <DateCalendar
                                value={endDate}
                                onChange={handleStartEndChange}
                                minDate={startDate ?? undefined}
                                slots={{ day: renderDate }}
                                disableHighlightToday={true}
                            />
                        )}
                    </InputContainer>
                </Body>
                <Footer>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Footer>
            </Root>
        </Menu>
    );
};
