import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useCreateLinkView } from "../../../sdk/hooks";
import { ILinkView } from "../../../sdk/types";
import {
    IFilterListItem,
    ILinkViewChangeEvent,
    LinkViewUtils,
} from "../../../sdk/utils";

import { FilterList } from "./FilterList";

const Root = styled(Dialog)``;

const Content = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    max-width: 400px;
`;

const Title = styled(DialogTitle)`
    font-family: Poppins;
`;

const InputField = styled(TextField)``;

const ActionContainer = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(1)};
`;

interface ICreateLinkViewForm {
    title: string;
    viewState: IFilterListItem[];
}

const validationSchema = Yup.object({
    title: Yup.string()
        .min(3, "Title must be at least 3 characters")
        .required("Title is required"),
    viewState: Yup.array().min(1, "Atleast one filter required"),
});

export interface ICreateLinkViewDialogProps {
    id: string;
    label: string;
    view: ILinkView;
    open: boolean;
    onClose: () => void;
    onCreateEvent: (event: ILinkViewChangeEvent) => void;
}

export const CreateLinkViewDialog: FunctionComponent<
    ICreateLinkViewDialogProps
> = (props: ICreateLinkViewDialogProps): ReactElement => {
    const { id, view, label, open, onClose, onCreateEvent } = props;

    const [viewState, setViewState] = useState<ILinkView>(view);

    const createLinkViewMutation = useCreateLinkView(["link-views"], {
        onSuccess: (data: ILinkView) => {
            onCreateEvent({
                id: data.id,
                value: data,
                event: "create",
            });
            onClose();
        },
    });

    const handleSubmitEvent = useCallback(
        (values: ICreateLinkViewForm) => {
            createLinkViewMutation.mutate({
                ...viewState,
                label: values.title,
            });
        },
        [viewState, createLinkViewMutation],
    );

    const formik = useFormik<ICreateLinkViewForm>({
        initialValues: {
            title: "",
            viewState: LinkViewUtils.getFilterList(view.filters),
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmitEvent,
    });

    const handleFilterListChange = useCallback(
        (newViewState: ILinkView) => {
            formik.setFieldValue(
                "viewState",
                LinkViewUtils.getFilterList(newViewState.filters),
            );
            setViewState(newViewState);
        },
        [formik],
    );

    useEffect(() => {
        formik.resetForm();
        formik.setValues({
            title: "",
            viewState: LinkViewUtils.getFilterList(view.filters),
        });
        setViewState(view);
    }, [id, open, JSON.stringify(view)]);

    return (
        <Root open={open} onClose={onClose}>
            <Title>{label}</Title>
            <form onSubmit={formik.handleSubmit}>
                <Content>
                    <InputField
                        name="title"
                        label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        error={
                            formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={formik.touched.title && formik.errors.title}
                    />
                    <div>
                        <FilterList
                            view={viewState}
                            onChange={handleFilterListChange}
                        />
                        {formik.touched.viewState &&
                            formik.errors.viewState && (
                                <Typography color="error" variant="body2">
                                    {formik.errors.viewState.toString()}
                                </Typography>
                            )}
                    </div>
                    <Alert
                        variant="outlined"
                        color="warning"
                        icon={<Icon>warning</Icon>}
                    >
                        A new link view will be created based on the selected
                        filters, while the current view will remain unchanged.
                        To modify the current view, use the edit action.
                    </Alert>
                </Content>
                <ActionContainer>
                    <Button onClick={onClose} size="small" variant="contained">
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        size="small"
                        variant="contained"
                        loading={createLinkViewMutation.isLoading}
                    >
                        Save
                    </LoadingButton>
                </ActionContainer>
            </form>
        </Root>
    );
};
