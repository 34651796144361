import {
    FormHelperText,
    Select,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import { ArrowDropDown } from "@mui/icons-material";

import { IAdmin } from "../../../sdk/types";
import {
    AllocationMethod,
    CustomerSelection,
    DiscountCodeStatus,
    DiscountType,
    PriceRuleCategoryType,
    PriceRuleStatus,
} from "../../../utils/enums";

export const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #626262;
`;

export const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

export const TextFieldContainer = styled("div")`
    height: 55px;
    flex: 1;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
        width: 100%;
    }
  `}
`;

export const SelectField = styled(Select)`
    height: 40px;
`;

export const ErrorMessage = styled(FormHelperText)`
    padding: ${({ theme }) => theme.spacing(1)};
    color: red;
`;

export const RadioErrorMessage = styled(FormHelperText)`
    color: red;
    margin-left: 30px;
`;

export const StyledTooltip = styled(Tooltip)`
    cursor: default;
`;

export const StyledArrowDown = styled(ArrowDropDown)`
    margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const DiscountSection = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

export const SectionTitleContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

export interface IFormDiscount {
    id: string;
    code: string;
    status: DiscountCodeStatus;
    createdBy: IAdmin;
}

export interface IPriceRuleForm {
    name: string;
    description: string;
    status: PriceRuleStatus;
    notes: string | null;
    discountCodes: IFormDiscount[];
    category: PriceRuleCategoryType;
    discountType: DiscountType;
    discountValue: string;
    prereqSubtotalMin: string;
    prereqQuantityMin: string;
    prereqPaymentMethod: "cod";
    prereqVariantsRequired: number;
    variantIds: string[];
    allocationMethod: AllocationMethod;
    customerSelection: CustomerSelection;
    prereqCustomerGroupIds: string[];
    usageLimitByTotal: string;
    usageLimitByCustomer: string;
    startDate: Date;
    startTime: Date;
    enableEndTime: boolean;
    endDate: Date;
    endTime: Date;
    maxDiscountAmount: string;
}

export const disableScrollWheelInput = (
    event: React.WheelEvent<HTMLDivElement>,
) => {
    event.currentTarget.querySelector("input")?.blur();
};
