import {
    ChangeEvent,
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import {
    Alert,
    Button,
    Icon,
    IconButton,
    Paper,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { StickyBottomBar } from "../../components/common";
import { useGetAllCustomers } from "../../hooks";
import {
    useCreateCustomerGroup,
    useDeleteCustomerGroup,
    useGetCustomerGroupById,
    useUpdateCustomerGroup,
} from "../../sdk/hooks";
import { useMessage, useToggleCallbacks } from "../../sdk/hooks";
import { IPage } from "../../types";
import { globalDateTimeFormat } from "../../utils/constants";

import CustomersTableModal from "./CustomersTableModal";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};
    margin: ${({ theme }) => theme.spacing(0, "auto")};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            gap: ${theme.spacing(3)};
    }
  `}
`;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const FieldContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Container = styled("div")`
    height: 100vh;
`;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 250px;
`;

const LongTextFieldContainer = styled("div")`
    height: 40px;
    width: 500px;
`;

const TopContainer = styled("div")`
    display: flex;
    justify-content: space-between;

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            flex-direction: column
    }
  `}
`;

const TableHeader = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: ${({ theme }) => theme.spacing(6)};
`;

const NoCustomersContainer = styled(Paper)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 200px;
`;

const NoCustomersText = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: grey;
`;

const FlexCenterContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FileInputToolbar = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: ${({ theme }) => theme.spacing(3, 0)};
`;

const FileInputContainer = styled("div")``;

const FileDropzone = styled(FlexCenterContainer)`
    border: 0.2px solid gray;
    border-radius: 5px;

    height: 300px;
`;

const FileDropzoneWrapper = styled(FlexCenterContainer)`
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    position: relative;
    top: ${({ theme }) => theme.spacing(4)};
`;

const FileInput = styled(FlexCenterContainer)`
    width: 200px;
`;

const FileInputWarningMessage = styled(Alert)`
    width: 60%;

    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const HiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;

    position: absolute;
    bottom: 0;
    left: 0;

    white-space: nowrap;

    height: 1px;
    width: 5px;
`;

const FileWrapper = styled("div")`
    width: 60%;
`;

const FileContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    padding: ${({ theme }) => theme.spacing(0, 1)};

    border: 0.2px solid gray;
    border-radius: ${({ theme }) => theme.spacing(0.5)};
`;

const FileName = styled(Typography)`
    font-size: small;
    font-weight: 400;
`;

const FileDeleteIcon = styled(Icon)`
    color: #e86c6c;
`;

export interface ICustomerGroupForm {
    name: string;
    description: string;
    fileType: string;
    customerIds: string[];
    customerGroupId: string;
}

const validationSchema = Yup.object({
    name: Yup.string().min(1).required("Name is required"),
    description: Yup.string().min(1).required("Description is required"),
    customerIds: Yup.array()
        .of(Yup.string().uuid())
        .required("Atleast one customer is required"),
    fileType: Yup.string().oneOf(["text/csv"], "File format not supported"),
});

const columns: GridColDef[] = [
    {
        field: "firstName",
        headerName: "First name",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "lastName",
        headerName: "Last name",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "emailAddress",
        headerName: "Email address",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

const SAMPLE_CSV = `phoneNumber,firstName,lastName,emailAddress,gender,country,currency,pictureURL,birthday,emailMarketingConsent,smsMarketingConsent
+918765432100,John,Doe,john.doe@example.com,male,IND,INR,,1990-05-15,true,false
+911234567890,Jane,,jane.smith@example.com,female,USA,USD,https://example.com/images/jane.png,,true,false`;

export const CustomerGroupScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const createNewCustomerGroup = params.id === "new";
    const navigate = useNavigate();

    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: "",
        direction: "after",
    });
    const [pageTitle, setPageTitle] = useState<string>("");
    const [action, setAction] = useState<string>("");
    const [snackbarAction, setSnackbarAction] = useState<string>("");
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>(
        [],
    );
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [handleOpen, handleClose] = useToggleCallbacks(setOpenDialog);
    const { showError, showSuccess } = useMessage();

    const fileRef = useRef<HTMLInputElement>(null);

    const updateCustomerGroupMutation = useUpdateCustomerGroup(
        "customerGroups",
        {
            onSuccess: () => {
                handleReset();
                navigate("/customer-groups");
            },
        },
    );
    const deleteCustomerGroupMutation =
        useDeleteCustomerGroup("customerGroups");
    const createCustomerGroupMutation = useCreateCustomerGroup(
        "customerGroups",
        {
            onSuccess: () => {
                handleReset();
                navigate("/customer-groups");
            },
        },
    );
    const customerQuery = useGetAllCustomers("customers", {
        limit: page.limit,
        cursor: page.cursor,
        direction: page.direction,
    });
    const customerGroupQuery = useGetCustomerGroupById(
        ["customerGroups", params.id ?? "new"],
        params.id ?? "",
        {
            enabled: params.id !== "new" && params.id !== "",
            onSuccess: (data) => {
                setSelectedCustomerIds(data?.customerIds ?? []);
                setFieldValue("name", data?.name);
                setFieldValue("description", data?.description);
                setFieldValue("id", data?.id);
                setFieldValue("customerIds", data?.customerIds);
            },
        },
    );
    const selectedCustomers =
        customerQuery.data?.records.filter((customer) =>
            selectedCustomerIds.includes(customer.id),
        ) ?? [];
    const existingCustomerIds = customerGroupQuery.data?.customerIds ?? [];

    const handleFormSubmit = useCallback(
        async (values: ICustomerGroupForm) => {
            if (createNewCustomerGroup) {
                createCustomerGroupMutation.mutate(
                    {
                        name: values.name,
                        description: values.description,
                        customerIds: values.customerIds,
                        importCustomersFile: currentFile,
                    },
                    {
                        onSuccess: () => {
                            showSuccess("Customer group created successfully");
                        },
                        onError: () => {
                            showError("Customer Group creation failed");
                        },
                    },
                );
            }
            if (!createNewCustomerGroup) {
                updateCustomerGroupMutation.mutate(
                    {
                        name: values.name,
                        description: values.description,
                        customerIds: values.customerIds,
                        customerGroupId: values.customerGroupId,
                        importCustomersFile: currentFile,
                    },
                    {
                        onSuccess: () => {
                            showSuccess("Customer group updated successfully");
                        },
                        onError: () => {
                            showError("Customer Group updation failed");
                        },
                    },
                );
            }
        },
        [
            currentFile,
            createNewCustomerGroup,
            createCustomerGroupMutation,
            updateCustomerGroupMutation,
            showSuccess,
            showError,
        ],
    );
    const {
        values,
        handleChange,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        isValid,
        resetForm,
    } = useFormik<ICustomerGroupForm>({
        initialValues: {
            name: customerGroupQuery.data?.name ?? "",
            description: customerGroupQuery.data?.description ?? "",
            customerIds: selectedCustomerIds,
            customerGroupId: params.id ?? "",
            fileType: "",
        },
        validationSchema,
        onSubmit: handleFormSubmit,
    });
    const filetypeFormikError = touched.fileType && errors.fileType;
    const formIncomplete = Boolean(
        !isValid || (!currentFile && selectedCustomerIds.length === 0),
    );

    const handleReset = useCallback(() => {
        resetForm();
        setCurrentFile(null);
    }, [resetForm]);

    const handleDelete = useCallback(() => {
        deleteCustomerGroupMutation.mutate(
            {
                customerGroupId: params.id ?? "",
            },
            {
                onSuccess: () => {
                    showError("Customer group deleted successfully");
                    navigate("/customer-groups");
                },
            },
        );
    }, [deleteCustomerGroupMutation, navigate, params.id, showError]);

    const handleSelectedCustomerIds = useCallback(
        (newSelection: GridRowSelectionModel) => {
            if (newSelection.length === 0 && !currentFile) {
                showError(
                    "At least one customer must be selected. Please either select a customer or import one from a CSV file.",
                );
            } else {
                const selection = newSelection as string[];
                setSelectedCustomerIds(selection);
                setFieldValue("customerIds", selection);
            }
        },
        [currentFile, showError, setFieldValue],
    );

    const handleSave = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const handleDiscard = useCallback(() => {
        navigate("/customer-groups");
    }, [navigate]);

    const handleFileChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.item(0);

            if (!file) {
                setCurrentFile(null);
                return;
            }

            const fileFormat = file.type.length === 0 ? "unknown" : file.type;

            await setFieldTouched("fileType", true);
            await setFieldValue("fileType", fileFormat, true);

            setCurrentFile(file);
        },
        [setFieldTouched, setFieldValue],
    );

    const handleFileClear = useCallback(async () => {
        setCurrentFile(null);
        setFieldValue("fileType", "");

        if (fileRef.current) {
            /* creating a new empty file list using ref because file input is not a controlled input */
            fileRef.current.files = new DataTransfer().files;
        }
    }, [setFieldValue]);

    const handleDownloadSampleClick = useCallback(() => {
        const csvData = new Blob([SAMPLE_CSV], { type: "text/csv" });
        const csvURL = URL.createObjectURL(csvData);

        const link = document.createElement("a");
        link.href = csvURL;
        link.download = "sample.csv";
        link.click();
        link.remove();
    }, []);

    useEffect(() => {
        if (createNewCustomerGroup) {
            setPageTitle("Create");
            setSelectedCustomerIds([]);
            setAction("Select");
            setSnackbarAction("Added");
        } else {
            setPageTitle("Edit");
            setAction("Update");
            setSnackbarAction("Updated");
        }
    }, [createNewCustomerGroup, customerGroupQuery.data?.customerIds]);

    return (
        <>
            <Container>
                <Root>
                    <TopContainer>
                        <LeftContainer>
                            <Title>{pageTitle} customer group</Title>
                            <FieldContainer>
                                <TextFieldContainer>
                                    <TextField
                                        fullWidth={true}
                                        name="name"
                                        label="Name"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.name) && touched.name
                                        }
                                        helperText={touched.name && errors.name}
                                    />
                                </TextFieldContainer>
                            </FieldContainer>
                            <FieldContainer>
                                <LongTextFieldContainer>
                                    <TextField
                                        fullWidth={true}
                                        name="description"
                                        label="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        rows={4}
                                        multiline={true}
                                        error={
                                            Boolean(errors.description) &&
                                            touched.description
                                        }
                                        helperText={
                                            touched.description &&
                                            errors.description
                                        }
                                    />
                                </LongTextFieldContainer>
                            </FieldContainer>
                        </LeftContainer>
                    </TopContainer>
                    <FieldContainer>
                        <TableHeader>
                            <Title>Customers</Title>
                            {!createNewCustomerGroup && (
                                <IconButton onClick={handleOpen}>
                                    <Icon color="primary" fontSize="small">
                                        edit_icon
                                    </Icon>
                                </IconButton>
                            )}
                            {createNewCustomerGroup && (
                                <IconButton onClick={handleOpen}>
                                    <Icon color="primary">add_circle</Icon>
                                </IconButton>
                            )}
                        </TableHeader>
                        {(selectedCustomers ?? []).length > 0 && (
                            <DataGrid
                                rows={selectedCustomers ?? []}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 20,
                                        },
                                    },
                                }}
                                rowSelectionModel={selectedCustomerIds}
                                onRowSelectionModelChange={
                                    handleSelectedCustomerIds
                                }
                                pageSizeOptions={[10, 20, 30]}
                                disableRowSelectionOnClick={true}
                                checkboxSelection={true}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: {
                                            disableToolbarButton: true,
                                        },
                                        printOptions: {
                                            disableToolbarButton: true,
                                        },
                                    },
                                }}
                                disableColumnSelector={true}
                                disableDensitySelector={true}
                            />
                        )}
                    </FieldContainer>
                    {openDialog && (
                        <CustomersTableModal
                            onClose={handleClose}
                            openModal={openDialog}
                            onSelectCustomerIds={handleSelectedCustomerIds}
                            selectedCustomerIds={selectedCustomerIds}
                            action={action}
                            snackbarAction={snackbarAction}
                            existingCustomerIds={existingCustomerIds}
                        />
                    )}
                    {selectedCustomers?.length === 0 && (
                        <NoCustomersContainer variant="outlined" elevation={0}>
                            <NoCustomersText>
                                No customers have been selected.
                            </NoCustomersText>
                        </NoCustomersContainer>
                    )}
                    <FileInputContainer>
                        <FileInputToolbar>
                            <Title>Import from CSV</Title>
                            <Button
                                variant="outlined"
                                startIcon={<Icon>download</Icon>}
                                onClick={handleDownloadSampleClick}
                            >
                                Download sample
                            </Button>
                        </FileInputToolbar>
                        <FileDropzone>
                            <FileDropzoneWrapper>
                                {!currentFile && (
                                    <FileInput>
                                        <Button
                                            component={"label"}
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            fullWidth={true}
                                            startIcon={
                                                <Icon>cloud_upload</Icon>
                                            }
                                        >
                                            Upload file
                                            <HiddenInput
                                                name="file"
                                                type="file"
                                                ref={fileRef}
                                                onChange={handleFileChange}
                                            />
                                        </Button>
                                    </FileInput>
                                )}
                                {currentFile && (
                                    <FileWrapper>
                                        <FileContainer>
                                            <FlexCenterContainer>
                                                <Icon>upload_file</Icon>
                                                <FileName>
                                                    {currentFile.name}
                                                </FileName>
                                            </FlexCenterContainer>
                                            <IconButton
                                                onClick={handleFileClear}
                                            >
                                                <FileDeleteIcon>
                                                    delete
                                                </FileDeleteIcon>
                                            </IconButton>
                                        </FileContainer>
                                    </FileWrapper>
                                )}
                                {filetypeFormikError && (
                                    <FileInputWarningMessage severity="error">
                                        {errors.fileType}
                                    </FileInputWarningMessage>
                                )}
                                {!filetypeFormikError && (
                                    <FileInputWarningMessage
                                        variant="outlined"
                                        severity="warning"
                                    >
                                        If the CSV has phone numbers of users
                                        who have not previously signed up on the
                                        website, new customer accounts will be
                                        created for such phone numbers on
                                        importing the file
                                    </FileInputWarningMessage>
                                )}
                            </FileDropzoneWrapper>
                        </FileDropzone>
                    </FileInputContainer>
                </Root>
                {createNewCustomerGroup && (
                    <StickyBottomBar
                        cancelText={"Discard"}
                        submitText={"Save"}
                        onCancel={handleDiscard}
                        onSubmit={handleSave}
                        disabled={formIncomplete}
                        loading={createCustomerGroupMutation.isLoading}
                    />
                )}
                {!createNewCustomerGroup && (
                    <StickyBottomBar
                        cancelText={"Delete"}
                        submitText={"Save"}
                        onCancel={handleDelete}
                        onSubmit={handleSave}
                        disabled={formIncomplete}
                        loading={updateCustomerGroupMutation.isLoading}
                    />
                )}
            </Container>
        </>
    );
};
