import axios from "axios";

import { ILinkView } from "../../types";

export interface ICreateLinkViewParams
    extends Omit<ILinkView, "id" | "createdAt" | "updatedAt"> {
    token: string;
}

export interface IUpdateLinkViewParams
    extends Omit<ILinkView, "createdAt" | "updatedAt"> {
    token: string;
}

export interface IDeleteLinkViewParams {
    id: string;
    token: string;
}

export interface IListLinkViewParams {
    limit: number;
    token: string;
}

export interface IListAllLinkViewsResult {
    records: ILinkView[];
}

export class LinkView {
    private static transform(data: any): ILinkView {
        return {
            ...data,
            filters: {
                ...data?.filters,
                createdAt: {
                    minDate: data?.filters?.createdAt?.minDate
                        ? new Date(data?.filters?.createdAt?.minDate)
                        : null,
                    maxDate: data?.filters?.createdAt?.maxDate
                        ? new Date(data?.filters?.createdAt?.maxDate)
                        : null,
                },
            },
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    }

    public static async create(
        params: ICreateLinkViewParams,
    ): Promise<ILinkView> {
        const { token, ...body } = params;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links-views`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return LinkView.transform(data);
    }

    public static async update(
        params: IUpdateLinkViewParams,
    ): Promise<ILinkView> {
        const { token, id, ...body } = params;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links-views/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return LinkView.transform(data);
    }

    public static async delete(
        params: IDeleteLinkViewParams,
    ): Promise<ILinkView> {
        const { token, id } = params;
        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links-views/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return LinkView.transform(data);
    }

    public static async listAll(
        params: IListLinkViewParams,
    ): Promise<IListAllLinkViewsResult> {
        const { token, limit } = params;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links-views`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    limit: limit,
                },
            },
        );
        return {
            ...data,
            records: data.records?.map(LinkView.transform),
        };
    }
}
