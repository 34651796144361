import { AxiosError } from "axios";
import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { ILinkView } from "../../types";
import { api } from "../../utils";
import { IUpdateLinkViewParams } from "../../utils/api";
import { useAuth } from "../useAuth";

export interface IUpdateLinkViewMutationParameters
    extends Omit<IUpdateLinkViewParams, "token"> {}

/**
 * @author Nikhil babar <nikhil.babar@houseofx.in>
 */
export const useUpdateLinkView = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        ILinkView,
        AxiosError,
        IUpdateLinkViewMutationParameters,
        unknown
    >,
): UseMutationResult<
    ILinkView,
    AxiosError,
    IUpdateLinkViewMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        ILinkView,
        AxiosError,
        IUpdateLinkViewMutationParameters
    >({
        ...options,
        mutationFn: (
            parameters: IUpdateLinkViewMutationParameters,
        ): Promise<ILinkView> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot import links without authorization token.",
                );
            }

            return api.LinkView.update({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: (
            linkView: ILinkView,
            variables: IUpdateLinkViewMutationParameters,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(linkView, variables, context);
        },
    });
};
