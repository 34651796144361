import { FunctionComponent, ReactElement } from "react";

import { Button, Icon, styled } from "@mui/material";

import { InstantSearch } from "react-instantsearch";
import { Link } from "react-router-dom";

import { CreateFromFileMenu } from "./CreateFromFileMenu";
import { LinksGrid } from "./LinksDataGrid";
import { typesenseAdapter } from "./config/typesense.config.adapter";

const Root = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Heading = styled("div")`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;

const CreateContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

export const ViewLinksScreen: FunctionComponent = (): ReactElement => {
    return (
        <InstantSearch
            searchClient={typesenseAdapter.searchClient}
            indexName={`${process.env.REACT_APP_TYPESENSE_PREFIX}links`}
            future={{
                preserveSharedStateOnUnmount: true,
            }}
        >
            <Root>
                <TitleContainer>
                    <Heading>Links</Heading>
                    <CreateContainer>
                        <Link to="/links/new/edit">
                            <Button
                                size="small"
                                variant="contained"
                                disableElevation={true}
                                endIcon={<Icon>add_circle</Icon>}
                            >
                                Create
                            </Button>
                        </Link>
                        <CreateFromFileMenu />
                    </CreateContainer>
                </TitleContainer>
                <LinksGrid />
            </Root>
        </InstantSearch>
    );
};
