import { useCallback, useEffect, useState } from "react";

import {
    Button,
    Icon,
    IconButton,
    Menu,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
} from "@mui/material";

import { useRange } from "react-instantsearch";

import { TypesenseNumericFilterType } from "../../../sdk/types/enums";
import { misc } from "../../../sdk/utils";

const FlexContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: start;
`;

const Root = styled(FlexContainer)`
    width: 240px;

    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(2)};
`;

const Header = styled("div")`
    display: flex;
    justify-content: start;
    align-items: center;
`;

const Body = styled("div")``;

const InputContainer = styled("div")``;

const Footer = styled(FlexContainer)`
    width: 100%;

    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Title = styled(Typography)``;

const BackButton = styled(IconButton)``;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};

    .Mui-selected {
        background-color: #cbdef0 !important;
    }
`;

const StyledToggleButton = styled(ToggleButton)`
    flex: 1;

    text-transform: none;
`;

const HorizontalDivider = styled(Typography)`
    align-self: center;
`;

export interface IRangeChangeEvent {
    id: string;
    attribute: string;
    value: {
        exactValue: number | null;
        mode: TypesenseNumericFilterType;
        range: {
            min: number | null;
            max: number | null;
        };
    };
}

export interface IRangeInputProps {
    id: string;
    attribute: string;
    label: string;
    mode: TypesenseNumericFilterType | null;
    exactValue: number | null;
    range: {
        min: number | null;
        max: number | null;
    };
    handleChange: (params: IRangeChangeEvent) => void;
    handleClose: () => void;
    open: boolean;
    anchorElement: Element;
}

export const RangeInput: React.FunctionComponent<IRangeInputProps> = (
    props: IRangeInputProps,
): React.ReactElement => {
    const {
        id,
        range,
        exactValue,
        attribute,
        label,
        handleChange,
        handleClose,
        open,
        mode,
        anchorElement,
    } = props;

    /*
        The useInstantSearch hook requires either a mounted widget or a virtualized widget.
        Therefore, we use the useRange hook to mount a virtual widget.
        Mounting a range widget enables the use of the range prop within setIndexUiState.
    */
    useRange({
        attribute,
    });

    const [currentAlignment, setCurrentAlignment] = useState<number>(
        mode ?? TypesenseNumericFilterType.EXACT_VALUE,
    );
    const [exactValueState, setExactValueState] = useState<number | null>(
        exactValue,
    );
    const [minValue, setMinValue] = useState<number | null>(range.min);
    const [maxValue, setMaxValue] = useState<number | null>(range.max);

    const handleAlignmentChange = useCallback(
        (_event: unknown, value: string) => {
            setCurrentAlignment(Number(value));
        },
        [],
    );

    const handleValueChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;

            if (name === "min") {
                setMinValue(misc.toNullableInteger(value));
            } else if (name === "max") {
                setMaxValue(misc.toNullableInteger(value));
            } else {
                setExactValueState(misc.toNullableInteger(value));
            }
        },
        [],
    );

    const handleSave = useCallback(() => {
        if (currentAlignment === TypesenseNumericFilterType.EXACT_VALUE) {
            setMinValue(null);
            setMaxValue(null);
            handleChange({
                id,
                attribute,
                value: {
                    mode: currentAlignment,
                    exactValue: exactValueState,
                    range: { min: null, max: null },
                },
            });
        } else if (currentAlignment === TypesenseNumericFilterType.RANGE) {
            setExactValueState(null);
            handleChange({
                id,
                attribute,
                value: {
                    mode: currentAlignment,
                    exactValue: null,
                    range: { min: minValue, max: maxValue },
                },
            });
        }
        handleClose();
    }, [
        id,
        attribute,
        currentAlignment,
        minValue,
        maxValue,
        exactValueState,
        handleChange,
        handleClose,
    ]);

    if (!open) {
        return <div></div>;
    }

    return (
        <Menu open={open} anchorEl={anchorElement}>
            <Root>
                <Header>
                    <BackButton onClick={handleClose}>
                        <Icon>arrow_back</Icon>
                    </BackButton>
                    <Title>{label}</Title>
                </Header>
                <Body>
                    <StyledToggleButtonGroup
                        value={currentAlignment}
                        onChange={handleAlignmentChange}
                        exclusive={true}
                        size="small"
                    >
                        <StyledToggleButton
                            value={TypesenseNumericFilterType.EXACT_VALUE}
                        >
                            Exact Value
                        </StyledToggleButton>
                        <StyledToggleButton
                            value={TypesenseNumericFilterType.RANGE}
                        >
                            Range
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                    <InputContainer>
                        {currentAlignment ===
                            TypesenseNumericFilterType.EXACT_VALUE && (
                            <TextField
                                variant="outlined"
                                size="small"
                                name="exact-value"
                                inputProps={{
                                    type: "number",
                                }}
                                value={exactValueState?.toString()}
                                onChange={handleValueChange}
                            />
                        )}
                        {currentAlignment ===
                            TypesenseNumericFilterType.RANGE && (
                            <FlexContainer>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    name="min"
                                    inputProps={{
                                        type: "number",
                                    }}
                                    value={minValue?.toString()}
                                    onChange={handleValueChange}
                                />
                                <HorizontalDivider>-</HorizontalDivider>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name="max"
                                    inputProps={{
                                        type: "number",
                                    }}
                                    value={maxValue?.toString()}
                                    onChange={handleValueChange}
                                />
                            </FlexContainer>
                        )}
                    </InputContainer>
                </Body>
                <Footer>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Footer>
            </Root>
        </Menu>
    );
};
