import {
    ChangeEvent,
    FunctionComponent,
    MouseEvent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import {
    Button,
    ButtonProps,
    FormControl,
    FormControlLabel,
    Popover,
    Radio,
    RadioGroup,
    ToggleButton,
    ToggleButtonGroup,
    styled,
} from "@mui/material";

import { useSortBy } from "react-instantsearch";

import { TypesenseSortOrder } from "../../../sdk/types/enums";

import { ISortItem } from "./SortMenuItem";

const Root = styled(Popover)`
    width: 200px;
`;

const Content = styled("div")`
    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const OrderButtonGroup = styled(ToggleButtonGroup)`
    padding: ${({ theme }) => theme.spacing(1)};

    .Mui-selected {
        background-color: #cbdef0 !important;
    }
`;

const OrderButton = styled(ToggleButton)`
    text-transform: none;
`;

export interface ISortChangeEvent {
    id: string;
    value: {
        attribute: string | null;
        order: TypesenseSortOrder | null;
    };
}

export interface ISortMenuProps {
    id: string;
    label: string;
    buttonProps?: ButtonProps;
    options: ISortItem[];
    attribute: string | null;
    order: TypesenseSortOrder | null;
    onChange: (event: ISortChangeEvent) => void;
}

export const SortMenu: FunctionComponent<ISortMenuProps> = (
    props: ISortMenuProps,
): ReactElement => {
    const { id, options, buttonProps, label, attribute, order, onChange } =
        props;

    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(
        null,
    );

    const open = Boolean(anchorElement);

    /*
        The useInstantSearch hook requires either a mounted widget or a virtualized widget.
        Therefore, we use the useSortBy hook to mount a virtual widget.
        Mounting a sort-by widget enables the use of the sortBy prop within setIndexUiState.
    */
    useSortBy({
        items: options.map((option) => ({
            label: option.label,
            value: option.attribute,
        })),
    });

    const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorElement(null);
    }, []);

    const handleSortOrderChange = useCallback(
        (_event: MouseEvent<HTMLElement>, order: TypesenseSortOrder) => {
            onChange({
                id,
                value: {
                    attribute,
                    order,
                },
            });
        },
        [id, onChange, attribute],
    );
    const handleSortFacetChange = useCallback(
        (_event: ChangeEvent<HTMLElement>, attribute: string) => {
            onChange({
                id,
                value: {
                    order,
                    attribute,
                },
            });
        },
        [id, onChange, order],
    );

    return (
        <>
            <Button onClick={handleClick} {...buttonProps}>
                {label}
            </Button>
            <Root
                anchorEl={anchorElement}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Content>
                    <FormControl>
                        <RadioGroup
                            value={attribute}
                            onChange={handleSortFacetChange}
                        >
                            {options.map((option: ISortItem) => (
                                <FormControlLabel
                                    key={option.attribute}
                                    value={option.attribute}
                                    control={<Radio size="small" />}
                                    label={option.label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Content>
                <OrderButtonGroup
                    value={order}
                    orientation="vertical"
                    exclusive={true}
                    onChange={handleSortOrderChange}
                    fullWidth={true}
                >
                    <OrderButton
                        value={TypesenseSortOrder.ASCENDING}
                        size="small"
                        fullWidth={true}
                    >
                        Ascending
                    </OrderButton>
                    <OrderButton
                        value={TypesenseSortOrder.DESCENDING}
                        size="small"
                    >
                        Descending
                    </OrderButton>
                </OrderButtonGroup>
            </Root>
        </>
    );
};
