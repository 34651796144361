import { AxiosError } from "axios";
import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { ICustomerGroup } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";

export interface IDeleteCustomerGroupMutationParameter {
    customerGroupId: string;
}

export const useDeleteCustomerGroup = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        ICustomerGroup,
        AxiosError,
        IDeleteCustomerGroupMutationParameter,
        unknown
    >,
): UseMutationResult<
    ICustomerGroup,
    AxiosError,
    IDeleteCustomerGroupMutationParameter
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        ICustomerGroup,
        AxiosError,
        IDeleteCustomerGroupMutationParameter
    >({
        mutationFn: async (
            parameters: IDeleteCustomerGroupMutationParameter,
        ) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete customer group without authorization token.",
                );
            }

            return await api.CustomerGroup.delete({
                ...parameters,
                token: auth.token,
            });
        },
        ...options,
        onSuccess: (
            customerGroup: ICustomerGroup,
            variables: IDeleteCustomerGroupMutationParameter,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(customerGroup, variables, context);
        },
    });
};
