import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { ILinkView } from "../../../sdk/types";
import { ILinkViewChangeEvent } from "../../../sdk/utils";

const Root = styled(Dialog)``;

const Content = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    max-width: 350px;
`;

const Title = styled(DialogTitle)`
    font-family: Poppins;
`;

const ActionContainer = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(1)};
`;

export interface IResetLinkViewDialogProps {
    id: string;
    label: string;
    view: ILinkView;
    open: boolean;
    onClose: () => void;
    onResetEvent: (id: ILinkViewChangeEvent) => void;
}

export const ResetLinkViewDialog: FunctionComponent<
    IResetLinkViewDialogProps
> = (props: IResetLinkViewDialogProps): ReactElement => {
    const { id, view, label, open, onClose, onResetEvent } = props;

    const handleSubmitEvent = useCallback(() => {
        onResetEvent({
            id,
            value: view,
            event: "reset",
        });
        onClose();
    }, [id, view, onResetEvent, onClose]);

    return (
        <Root open={open} onClose={onClose}>
            <Title>{label}</Title>
            <Content>
                <Alert
                    variant="outlined"
                    color="warning"
                    icon={<Icon>warning</Icon>}
                >
                    Do you want to reset {view.label} filter
                </Alert>
            </Content>
            <ActionContainer>
                <Button onClick={onClose} size="small" variant="contained">
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    size="small"
                    variant="contained"
                    onClick={handleSubmitEvent}
                >
                    Reset
                </LoadingButton>
            </ActionContainer>
        </Root>
    );
};
