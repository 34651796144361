import {
    FunctionComponent,
    MouseEvent,
    ReactElement,
    useCallback,
} from "react";

import { Chip, styled } from "@mui/material";

const StyledChip = styled(Chip)`
    background-color: #cbdef0;
`;

export interface IFilterChip {
    label: string;
    value: string;
    attribute: string;
    onDelete: (attribute: string, value: string) => void;
}

export const FilterChip: FunctionComponent<IFilterChip> = (
    props: IFilterChip,
): ReactElement => {
    const { attribute, value, label, onDelete } = props;

    const handleDelete = useCallback(() => {
        onDelete(attribute, value);
    }, [attribute, value, onDelete]);

    return <StyledChip label={label} onDelete={handleDelete} />;
};
