import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useUpdateLinkView } from "../../../sdk/hooks";
import { ILinkView } from "../../../sdk/types";
import {
    IFilterListItem,
    ILinkViewChangeEvent,
    LinkViewUtils,
} from "../../../sdk/utils";

import { FilterList } from "./FilterList";

const Root = styled(Dialog)``;

const Content = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    max-width: 400px;
`;

const Title = styled(DialogTitle)`
    font-family: Poppins;
`;

const InputField = styled(TextField)``;

const ActionContainer = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(1)};
`;

export interface IUpdateLinkViewForm {
    title: string;
    viewState: IFilterListItem[];
}

const validationSchema = Yup.object({
    title: Yup.string()
        .min(3, "Title must be at least 3 characters")
        .required("Title is required"),
    viewState: Yup.array().min(1, "At least one filter is required"),
});

export interface IUpdateLinkViewDialogProps {
    id: string;
    label: string;
    view: ILinkView;
    open: boolean;
    onClose: () => void;
    onUpdateEvent: (event: ILinkViewChangeEvent) => void;
}

export const UpdateLinkViewDialog: FunctionComponent<
    IUpdateLinkViewDialogProps
> = (props: IUpdateLinkViewDialogProps): ReactElement => {
    const { id, view, label, open, onClose, onUpdateEvent } = props;

    const [viewState, setViewState] = useState<ILinkView>(view);

    const updateLinkViewMutation = useUpdateLinkView(["link-views"], {
        onSuccess: (data: ILinkView) => {
            onUpdateEvent({
                id: data.id,
                value: data,
                event: "update",
            });
            onClose();
        },
    });

    const formik = useFormik<IUpdateLinkViewForm>({
        initialValues: {
            title: view.label ?? "",
            viewState: LinkViewUtils.getFilterList(view.filters),
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            updateLinkViewMutation.mutate({
                ...viewState,
                label: values.title,
            });
        },
    });

    useEffect(() => {
        formik.setValues({
            title: view.label ?? "",
            viewState: LinkViewUtils.getFilterList(view.filters),
        });
        setViewState(view);
    }, [id, open, JSON.stringify(view)]);

    const handleFilterListChange = useCallback(
        (newViewState: ILinkView) => {
            formik.setFieldValue(
                "viewState",
                LinkViewUtils.getFilterList(newViewState.filters),
            );
            setViewState(newViewState);
        },
        [formik],
    );

    return (
        <Root open={open} onClose={onClose}>
            <Title>{label}</Title>
            <form onSubmit={formik.handleSubmit}>
                <Content>
                    <InputField
                        name="title"
                        label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        size="small"
                        error={
                            formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={formik.touched.title && formik.errors.title}
                    />

                    <div>
                        <FilterList
                            view={viewState}
                            onChange={handleFilterListChange}
                        />
                        {formik.touched.viewState &&
                            formik.errors.viewState && (
                                <Typography color="error" variant="body2">
                                    {formik.errors.viewState.toString()}
                                </Typography>
                            )}
                    </div>
                    <Alert
                        variant="outlined"
                        color="warning"
                        icon={<Icon>warning</Icon>}
                    >
                        The current filter will be updated based on the selected
                        filters. A new filter will not be created. To apply a
                        new filter, use the create action.
                    </Alert>
                </Content>
                <ActionContainer>
                    <Button onClick={onClose} size="small" variant="contained">
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        size="small"
                        variant="contained"
                        loading={updateLinkViewMutation.isLoading}
                    >
                        Save
                    </LoadingButton>
                </ActionContainer>
            </form>
        </Root>
    );
};
