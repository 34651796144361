import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

import { TypesenseConfig } from "../../../utils/third-party/typesense";

export const typesenseAdapter = new TypesenseInstantsearchAdapter({
    server: TypesenseConfig,
    additionalSearchParameters: {
        query_by: "name,tags,alias,destination",
        facet_by: "name,tags,alias,destination,clicks,createdAt,scans",
        typo_tokens_threshold: 3,
        per_page: 20,
    },
});

export const LINKS_COLLECTION = `${process.env.REACT_APP_TYPESENSE_PREFIX}links`;
