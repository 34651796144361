import { FunctionComponent, useCallback, useEffect, useState } from "react";

import { styled } from "@mui/material";

import { ISplitButtonOption, SplitButton } from "../../components/common";
import { ILinkView } from "../../sdk/types";
import { ILinkViewChangeEvent } from "../../sdk/utils";

import { FilterList } from "./components";
import {
    CreateLinkViewDialog,
    DeleteLinkViewDialog,
    ResetLinkViewDialog,
    UpdateLinkViewDialog,
} from "./components";

const FlexContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Root = styled(FlexContainer)`
    justify-content: space-between;

    padding: ${({ theme }) => theme.spacing(1)};
`;

const ButtonGroupContainer = styled("div")`
    min-width: 200px;

    display: flex;
    justify-content: end;
    align-items: center;
`;

export enum LinkViewAction {
    CREATE = 0,
    UPDATE = 1,
    DELETE = 2,
    RESET = 3,
}

export interface ILinkViewFooterProps {
    id: string;
    filter: ILinkView;
    onChange: (filter: ILinkViewChangeEvent) => void;
}

export const LinkViewFooter: FunctionComponent<ILinkViewFooterProps> = (
    props: ILinkViewFooterProps,
) => {
    const { id, filter, onChange } = props;

    const [openDialog, setOpenSaveDialog] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<LinkViewAction>(
        LinkViewAction.CREATE,
    );
    const [options, setOptions] = useState<ISplitButtonOption[]>([]);

    const handleLinkViewChange = useCallback(
        (filter: ILinkView) => {
            onChange({
                id,
                value: filter,
                event: "filter-change",
            });
        },
        [onChange, id],
    );
    const handleLinkViewEvent = useCallback(
        (event: ILinkViewChangeEvent) => {
            onChange(event);
        },
        [onChange],
    );

    const handleDialogToggle = useCallback(() => {
        setOpenSaveDialog((previous) => !previous);
    }, []);

    const handleOptionsChange = useCallback((optionNumber: number) => {
        setSelectedOption(optionNumber);
    }, []);

    useEffect(() => {
        const list: ISplitButtonOption[] = [];
        list.push({
            value: "create",
            label: "Create View",
            disabled: false,
        });
        list.push({
            value: "edit",
            label: "Edit View",
            disabled: !filter.editable,
        });
        list.push({
            value: "delete",
            label: "Delete View",
            disabled: !filter.editable,
        });
        list.push({
            value: "reset",
            label: "Reset View",
            disabled: false,
        });
        setOptions(list);
        setSelectedOption(LinkViewAction.CREATE);
    }, [filter.editable]);

    return (
        <Root>
            <div>
                <FilterList
                    view={filter}
                    onChange={handleLinkViewChange}
                    showClearAll={true}
                />
            </div>
            <ButtonGroupContainer>
                <SplitButton
                    selectedOptionNumber={selectedOption}
                    size="small"
                    onChange={handleOptionsChange}
                    onClick={handleDialogToggle}
                    options={options}
                />
            </ButtonGroupContainer>
            <CreateLinkViewDialog
                id={id}
                open={openDialog && selectedOption === LinkViewAction.CREATE}
                label="Create View"
                view={filter}
                onCreateEvent={handleLinkViewEvent}
                onClose={handleDialogToggle}
            />
            <UpdateLinkViewDialog
                id={id}
                open={openDialog && selectedOption === LinkViewAction.UPDATE}
                label="Edit View"
                view={filter}
                onUpdateEvent={handleLinkViewEvent}
                onClose={handleDialogToggle}
            />
            <DeleteLinkViewDialog
                id={id}
                open={openDialog && selectedOption === LinkViewAction.DELETE}
                label="Delete View"
                view={filter}
                onDeleteEvent={handleLinkViewEvent}
                onClose={handleDialogToggle}
            />
            <ResetLinkViewDialog
                id={id}
                open={openDialog && selectedOption === LinkViewAction.RESET}
                label="Clear View"
                view={filter}
                onResetEvent={handleLinkViewEvent}
                onClose={handleDialogToggle}
            />
        </Root>
    );
};
