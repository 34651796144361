import { FunctionComponent, useCallback } from "react";

import { MenuItem } from "@mui/material";

export interface ISplitButtonOption {
    value: string;
    label: string;
    disabled: boolean;
}

export interface ISplitButtonMenuItemProps {
    option: ISplitButtonOption;
    index: number;
    selected: boolean;
    onClick: (index: number) => void;
}

export const SplitButtonMenuItem: FunctionComponent<
    ISplitButtonMenuItemProps
> = (props: ISplitButtonMenuItemProps) => {
    const { index, option, selected, onClick } = props;

    const handleClick = useCallback(() => {
        onClick(index);
    }, [onClick]);

    return (
        <MenuItem
            key={option.value}
            selected={selected}
            onClick={handleClick}
            disabled={option.disabled}
        >
            {option.label}
        </MenuItem>
    );
};
