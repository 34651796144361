import { useCallback, useState } from "react";

import { TSetterFunction } from "../types/common";

export interface IUseToggleCallbackParams {
    defaultMode?: boolean;
}

export type TUseToggleCallbackResponse = [boolean, () => void];

export const useToggleCallback = (
    options: IUseToggleCallbackParams = {},
): TUseToggleCallbackResponse => {
    const { defaultMode } = options;
    const [state, setState] = useState<boolean>(defaultMode ?? false);

    const handleToggle = useCallback(() => {
        setState((prev) => !prev);
    }, []);

    return [state, handleToggle];
};
