import { AxiosError } from "axios";
import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { ICustomerGroup } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";

export interface ICreateCustomerGroupMutationParameter {
    name: string;
    description: string;
    customerIds: string[];
    importCustomersFile: File | null;
}

export const useCreateCustomerGroup = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        ICustomerGroup,
        AxiosError,
        ICreateCustomerGroupMutationParameter,
        unknown
    >,
): UseMutationResult<
    ICustomerGroup,
    AxiosError,
    ICreateCustomerGroupMutationParameter
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        ICustomerGroup,
        AxiosError,
        ICreateCustomerGroupMutationParameter
    >({
        mutationFn: async (
            parameters: ICreateCustomerGroupMutationParameter,
        ): Promise<ICustomerGroup> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create customer group without authorization token.",
                );
            }

            return await api.CustomerGroup.create({
                ...parameters,
                token: auth.token,
            });
        },
        ...options,
        onSuccess: (
            customerGroup: ICustomerGroup,
            variables: ICreateCustomerGroupMutationParameter,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(customerGroup, variables, context);
        },
    });
};
