export enum LinkViewSortAttribute {
    CREATED_AT = 1,
    CLICKS = 2,
    SCANS = 3,
    ALIAS = 4,
}

export enum TypesenseSortOrder {
    ASCENDING = 1,
    DESCENDING = 2,
}

export enum TypesenseNumericFilterType {
    EXACT_VALUE = 1,
    RANGE = 2,
}
