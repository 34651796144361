import { FunctionComponent, MouseEventHandler, useCallback } from "react";

import { Chip, Icon, Link, styled } from "@mui/material";

import { GridRowParams } from "@mui/x-data-grid";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { Pagination, useHits } from "react-instantsearch";
import { useNavigate } from "react-router-dom";

import { ILink } from "../../sdk/types";
import { formatDate } from "../../sdk/utils/numbers";

import { LinkView } from "./LinkView";

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const AliasContainer = styled("div")`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(0.5)};
`;

const Tags = styled("div")`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(0.5)};
`;

const StyledPaginationContainer = styled("div")`
    .ais-Pagination-list {
        display: flex;
        justify-content: center;
        padding: ${({ theme }) => theme.spacing(2)};

        list-style: none;
    }
    .ais-Pagination-item {
        margin: ${({ theme }) => theme.spacing(0, 1)};
    }
    .ais-Pagination-link {
        padding: ${({ theme }) => theme.spacing(1, 2)};

        border: 1px solid ${({ theme }) => theme.palette.divider};
        border-radius: 4px;

        color: ${({ theme }) => theme.palette.text.primary};
        text-decoration: none;

        cursor: pointer;
        transition: background-color 0.2s ease;
        &:hover {
            background-color: ${({ theme }) => theme.palette.action.hover};
        }
    }

    .ais-Pagination-item--disabled .ais-Pagination-link {
        color: ${({ theme }) => theme.palette.action.disabled};
        cursor: not-allowed;
    }

    .ais-Pagination-item--selected .ais-Pagination-link {
        background-color: #042442;
        color: white;

        cursor: default;
    }
`;

export const LinksGrid: FunctionComponent = () => {
    const navigate = useNavigate();
    const { hits }: { hits: ILink[] } = useHits();

    const handleEdit = useCallback(
        (id: string) => () => {
            navigate(`/links/${id}/edit`);
        },
        [navigate],
    );

    const columns: GridColDef[] = [
        {
            field: "alias",
            headerName: "Alias",
            width: 500,
            editable: false,
            renderCell: (params) => {
                const aliasUrl = `${
                    process.env.REACT_APP_LINK_BASE_URL ??
                    "https://links.ifeelblanko.com"
                }/${params.row.alias}`;

                const handleClick: MouseEventHandler<HTMLAnchorElement> = (
                    event,
                ) => {
                    window.open(aliasUrl, "_blank");
                    event.stopPropagation();
                    event.preventDefault();
                };

                return (
                    <AliasContainer>
                        <Link
                            onClick={handleClick}
                            href={aliasUrl}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                textUnderlineOffset: 4,
                            }}
                        >
                            {aliasUrl}&nbsp;
                            <Icon
                                sx={{ ml: 0.5, fontSize: 14 }}
                                fontSize="small"
                            >
                                open_in_new
                            </Icon>
                        </Link>
                    </AliasContainer>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 200,
            editable: true,
        },
        {
            field: "createdAt",
            headerName: "Created at",
            type: "string",
            width: 110,
            editable: true,
            valueGetter: (params: GridValueGetterParams) => {
                const dateString = new Date(params.row.createdAt);
                return formatDate(dateString);
            },
        },
        {
            field: "clicks",
            headerName: "Clicks",
            type: "number",
            width: 110,
            editable: false,
        },
        {
            field: "scans",
            headerName: "Scans",
            type: "number",
            width: 110,
            editable: false,
        },
        {
            field: "tags",
            headerName: "Tags",
            description: "The tags associated with the URL.",
            sortable: false,
            width: 400,
            renderCell: (params) => (
                <Tags>
                    {params.row.tags.map((tag: string) => (
                        <Chip id={tag} label={tag} size="small" color="info" />
                    ))}
                </Tags>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            type: "actions",
            width: 110,
            editable: false,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<Icon>edit</Icon>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEdit(params.row.id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/links/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <>
            <LinkView />
            <StyledDataGrid
                autoHeight={true}
                rows={hits}
                columns={columns}
                onRowClick={handleRowClick}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: {
                            disableToolbarButton: false,
                        },
                        printOptions: {
                            disableToolbarButton: true,
                        },
                    },
                }}
            />
            <StyledPaginationContainer>
                <Pagination />
            </StyledPaginationContainer>
        </>
    );
};
