import { AxiosError } from "axios";
import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { useAuth } from "..";
import { ICustomerGroup } from "../../types";
import { api } from "../../utils";

export type TUseCustomerGroupByIdQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
>;

export const useGetCustomerGroupById = (
    queryKey: string[],
    customerGroupId: string,
    options?: TUseCustomerGroupByIdQueryOptions<
        unknown,
        AxiosError,
        ICustomerGroup,
        string[]
    >,
): UseQueryResult<ICustomerGroup> => {
    const auth = useAuth();

    return useQuery(
        queryKey,
        async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch customer group without authorization token.",
                );
            }

            return await api.CustomerGroup.getById({
                customerGroupId,
                token: auth.token,
            });
        },
        options,
    );
};
