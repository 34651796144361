import {
    FunctionComponent,
    MouseEvent,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from "react";

import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Icon,
    MenuList,
    Paper,
    Popper,
    styled,
} from "@mui/material";

import { useToggleCallback } from "../../sdk/hooks";

import { ISplitButtonOption, SplitButtonMenuItem } from "./SplitButtonMenuItem";

const StyledPopper = styled(Popper)`
    z-index: 1;
`;

const StyledButtonGroup = styled(ButtonGroup)`
    box-shadow: none;
`;

export interface ISplitButtonProps {
    size: "small" | "medium" | "large";
    selectedOptionNumber: number;
    options: ISplitButtonOption[];
    onChange: (option: number) => void;
    onClick: (event: MouseEvent<HTMLButtonElement>, option: number) => void;
}

export const SplitButton: FunctionComponent<ISplitButtonProps> = (
    props: ISplitButtonProps,
): ReactElement => {
    const { options, onChange, onClick, size, selectedOptionNumber } = props;

    const [splitMenu, toggleSplitMenu] = useToggleCallback({
        defaultMode: false,
    });
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = useCallback((index: number) => {
        onChange(index);
        toggleSplitMenu();
    }, []);

    const handleClose = useCallback((event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        toggleSplitMenu();
    }, []);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            onClick(event, selectedOptionNumber);
        },
        [onClick, selectedOptionNumber],
    );

    if (options.length === 0) {
        return <div></div>;
    }

    return (
        <>
            <StyledButtonGroup variant="contained" ref={anchorRef}>
                <Button
                    onClick={handleClick}
                    disabled={options[selectedOptionNumber].disabled}
                    size={size}
                >
                    {options[selectedOptionNumber].label}
                </Button>
                <Button size={size} onClick={toggleSplitMenu}>
                    <Icon>arrow_drop_down</Icon>
                </Button>
            </StyledButtonGroup>
            <StyledPopper
                open={splitMenu}
                anchorEl={anchorRef.current}
                transition={true}
                disablePortal={true}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={true}>
                                    {options.map((option, index) => (
                                        <SplitButtonMenuItem
                                            option={option}
                                            index={index}
                                            key={option.value}
                                            onClick={handleMenuItemClick}
                                            selected={
                                                index === selectedOptionNumber
                                            }
                                        />
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </StyledPopper>
        </>
    );
};
