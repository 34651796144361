import {
    ChangeEvent,
    FunctionComponent,
    ReactElement,
    useCallback,
} from "react";

import { Icon, TextField, styled } from "@mui/material";

import { useSearchBox } from "react-instantsearch";

const InputField = styled(TextField)`
    & .MuiInputBase-input {
        height: 16px;
    }
`;

export const SearchBox: FunctionComponent = (): ReactElement => {
    const { refine } = useSearchBox();

    const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            refine(value);
        },
        [refine],
    );

    return (
        <InputField
            variant="outlined"
            size="small"
            placeholder="Search Alias, Name, Destination"
            InputProps={{
                startAdornment: <Icon fontSize="small">search</Icon>,
            }}
            onChange={handleInputChange}
        />
    );
};
