import { FunctionComponent, ReactElement } from "react";

import {
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    TextField,
    Tooltip,
    styled,
} from "@mui/material";

import { Info, InfoRounded } from "@mui/icons-material";

import { FormikErrors, FormikTouched } from "formik";

import { Paper } from "../../../components/common";
import {
    DiscountType,
    DiscountTypeNames,
    PaymentMethodNames,
    PaymentMethodType,
    PriceRuleCategoryNames,
    PriceRuleCategoryType,
    PriceRuleStatus,
    priceRuleStatusNames,
} from "../../../utils/enums";

import {
    DiscountSection,
    IPriceRuleForm,
    RadioErrorMessage,
    Row,
    SectionTitle,
    SelectField,
    StyledArrowDown,
    StyledTooltip,
    TextFieldContainer,
    disableScrollWheelInput,
} from "./common";

const MultilineTextFieldContainer = styled("div")``;

export interface IGeneralSectionProps {
    values: IPriceRuleForm;
    touched: FormikTouched<IPriceRuleForm>;
    errors: FormikErrors<IPriceRuleForm>;
    /*
     * The below type comes from formik and is needed
     * because this component handles two kinds of change events,
     * from text fields and select fields.
     */
    onChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(
            field: T_1,
        ): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    onBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
}

const priceRuleStatusList = Object.values(PriceRuleStatus).filter(
    (value) => typeof value !== "string",
);
const paymentMethodsList = Object.values(PaymentMethodType);
const priceRuleCategoryList = Object.values(PriceRuleCategoryType).filter(
    (value) => typeof value !== "string",
);
const discountTypeList = Object.values(DiscountType).filter(
    (value) => typeof value !== "string",
);

export const GeneralSection: FunctionComponent<IGeneralSectionProps> = (
    props: IGeneralSectionProps,
): ReactElement => {
    const { values, errors, touched, onChange, onBlur } = props;
    return (
        <Paper>
            <DiscountSection>
                <SectionTitle>General</SectionTitle>
                <Row>
                    <TextFieldContainer>
                        <TextField
                            fullWidth={true}
                            required={true}
                            name="name"
                            label="Name"
                            value={values.name}
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            onBlur={onBlur}
                            error={Boolean(errors.name) && touched.name}
                            helperText={touched.name && errors.name}
                        />
                    </TextFieldContainer>
                    <TextFieldContainer>
                        <FormControl
                            size="small"
                            fullWidth={true}
                            required={true}
                        >
                            <InputLabel>Status</InputLabel>
                            <SelectField
                                name="status"
                                label="Status"
                                fullWidth={true}
                                size="small"
                                value={values.status}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={Boolean(errors.status) && touched.status}
                                IconComponent={(props) => (
                                    <StyledArrowDown {...props} />
                                )}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Tooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title="Status of the discount. Only active discounts are available for use."
                                        >
                                            <InfoRounded
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </InputAdornment>
                                }
                            >
                                {priceRuleStatusList.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        <ListItemText
                                            primary={
                                                priceRuleStatusNames[
                                                    Number(status)
                                                ]
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {Boolean(errors.status) && touched.status && (
                                <RadioErrorMessage>
                                    {errors.status}
                                </RadioErrorMessage>
                            )}
                        </FormControl>
                    </TextFieldContainer>
                </Row>
                <MultilineTextFieldContainer>
                    <TextField
                        fullWidth={true}
                        required={true}
                        multiline
                        rows={2}
                        name="description"
                        label="Description"
                        value={values.description}
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        onBlur={onBlur}
                        error={
                            Boolean(errors.description) && touched.description
                        }
                        helperText={touched.description && errors.description}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <StyledTooltip
                                        enterTouchDelay={0}
                                        placement="top"
                                        title={
                                            "Use this field to describe what this discount offers. NOTE: This description is shown to customers."
                                        }
                                    >
                                        <Info
                                            htmlColor="#626262"
                                            fontSize="small"
                                        />
                                    </StyledTooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </MultilineTextFieldContainer>
                <MultilineTextFieldContainer>
                    <TextField
                        fullWidth={true}
                        multiline
                        rows={2}
                        name="notes"
                        label="Notes"
                        value={values.notes}
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        onBlur={onBlur}
                        error={Boolean(errors.notes) && touched.notes}
                        helperText={touched.notes && errors.notes}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <StyledTooltip
                                        enterTouchDelay={0}
                                        placement="top"
                                        title={
                                            "Notes for internal use. You may use this field to keep a track of why this discount code was created. Notes are NOT shown to customers."
                                        }
                                    >
                                        <Info
                                            htmlColor="#626262"
                                            fontSize="small"
                                        />
                                    </StyledTooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </MultilineTextFieldContainer>
                <Row>
                    <TextFieldContainer>
                        <FormControl
                            size="small"
                            fullWidth={true}
                            required={true}
                        >
                            <InputLabel>Discount type</InputLabel>
                            <SelectField
                                name="discountType"
                                label="Discount type"
                                fullWidth={true}
                                size="small"
                                value={values.discountType}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={
                                    Boolean(errors.discountType) &&
                                    touched.discountType
                                }
                            >
                                {discountTypeList.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        <ListItemText
                                            primary={
                                                DiscountTypeNames[
                                                    type as DiscountType
                                                ]
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {Boolean(errors.discountType) &&
                                touched.discountType && (
                                    <RadioErrorMessage>
                                        {errors.discountType}
                                    </RadioErrorMessage>
                                )}
                        </FormControl>
                    </TextFieldContainer>
                    <TextFieldContainer>
                        <TextField
                            name="discountValue"
                            value={values.discountValue}
                            label={"Discount value"}
                            fullWidth={true}
                            required={true}
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            type="number"
                            onBlur={onBlur}
                            onWheel={disableScrollWheelInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {values.discountType ===
                                        DiscountType.FIXED
                                            ? "₹"
                                            : "%"}
                                    </InputAdornment>
                                ),
                            }}
                            error={
                                Boolean(errors.discountValue) &&
                                touched.discountValue
                            }
                            helperText={
                                touched.discountValue && errors.discountValue
                            }
                        />
                    </TextFieldContainer>
                </Row>
                {values.discountType === DiscountType.PERCENTAGE && (
                    <Row>
                        <TextFieldContainer>
                            <TextField
                                name="maxDiscountAmount"
                                value={values.maxDiscountAmount}
                                label={"Max discount amount"}
                                fullWidth={true}
                                onChange={onChange}
                                size="small"
                                variant="outlined"
                                type="number"
                                onBlur={onBlur}
                                onWheel={disableScrollWheelInput}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            ₹
                                        </InputAdornment>
                                    ),
                                }}
                                error={
                                    Boolean(errors.maxDiscountAmount) &&
                                    touched.maxDiscountAmount
                                }
                                helperText={
                                    touched.maxDiscountAmount &&
                                    errors.maxDiscountAmount
                                }
                            />
                        </TextFieldContainer>
                    </Row>
                )}
                <Row>
                    <TextFieldContainer>
                        <TextField
                            type="number"
                            name="usageLimitByTotal"
                            fullWidth={true}
                            value={values.usageLimitByTotal}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Total usage limit"
                            size="small"
                            variant="outlined"
                            onWheel={disableScrollWheelInput}
                            error={
                                Boolean(errors.usageLimitByTotal) &&
                                touched.usageLimitByTotal
                            }
                            helperText={
                                touched.usageLimitByTotal &&
                                errors.usageLimitByTotal
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <StyledTooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title={
                                                "Limit the number of times this discount can be used in total"
                                            }
                                        >
                                            <Info
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </StyledTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </TextFieldContainer>
                    <TextFieldContainer>
                        <TextField
                            type="number"
                            fullWidth={true}
                            name="usageLimitByCustomer"
                            value={values.usageLimitByCustomer}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Usage limit per customer"
                            size="small"
                            variant="outlined"
                            onWheel={disableScrollWheelInput}
                            error={
                                Boolean(errors.usageLimitByCustomer) &&
                                touched.usageLimitByCustomer
                            }
                            helperText={
                                touched.usageLimitByCustomer &&
                                errors.usageLimitByCustomer
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <StyledTooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title={
                                                "Limit the maximum no. of times this discount can be used by an eligible customer"
                                            }
                                        >
                                            <Info
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </StyledTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </TextFieldContainer>
                </Row>
                <Row>
                    <TextFieldContainer>
                        <FormControl
                            size="small"
                            fullWidth={true}
                            required={true}
                        >
                            <InputLabel>Category</InputLabel>
                            <SelectField
                                name="category"
                                label="Category"
                                fullWidth={true}
                                size="small"
                                value={values.category}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={
                                    Boolean(errors.category) && touched.category
                                }
                                IconComponent={(props) => (
                                    <StyledArrowDown {...props} />
                                )}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Tooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title={
                                                <>
                                                    Category of the discount
                                                    code.
                                                    <br />
                                                    <br />
                                                    Public discounts: Listed on
                                                    the storefront website
                                                    publicly. Can also be
                                                    applied by typing the
                                                    discount code.
                                                    <br />
                                                    <br />
                                                    Private discounts: Reserved
                                                    for specific
                                                    customers/purpose and are
                                                    not publicly listed. They
                                                    can only be applied by
                                                    typing the discount code.
                                                    <br />
                                                    <br />
                                                    Payment Method based
                                                    discounts: Associated with
                                                    specific payment methods and
                                                    are applied automatically.
                                                </>
                                            }
                                        >
                                            <InfoRounded
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </InputAdornment>
                                }
                            >
                                {priceRuleCategoryList.map((category) => (
                                    <MenuItem key={category} value={category}>
                                        <ListItemText
                                            primary={
                                                PriceRuleCategoryNames[
                                                    category as PriceRuleCategoryType
                                                ]
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {Boolean(errors.category) && touched.category && (
                                <RadioErrorMessage>
                                    {errors.category}
                                </RadioErrorMessage>
                            )}
                        </FormControl>
                    </TextFieldContainer>
                    {values.category ===
                        PriceRuleCategoryType.PAYMENT_METHODS && (
                        <TextFieldContainer>
                            {values.category ===
                                PriceRuleCategoryType.PAYMENT_METHODS && (
                                <FormControl
                                    size="small"
                                    fullWidth={true}
                                    required={
                                        values.category ===
                                        PriceRuleCategoryType.PAYMENT_METHODS
                                    }
                                >
                                    <InputLabel>Payment method</InputLabel>
                                    <SelectField
                                        name="prereqPaymentMethod"
                                        required={
                                            values.category ===
                                            PriceRuleCategoryType.PAYMENT_METHODS
                                        }
                                        label="Payment method"
                                        size="small"
                                        value={values.prereqPaymentMethod}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        error={
                                            Boolean(
                                                errors.prereqPaymentMethod,
                                            ) && touched.prereqPaymentMethod
                                        }
                                    >
                                        {paymentMethodsList.map((method) => (
                                            <MenuItem
                                                key={method}
                                                value={method}
                                            >
                                                <ListItemText
                                                    primary={
                                                        PaymentMethodNames[
                                                            method as PaymentMethodType
                                                        ]
                                                    }
                                                />
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                    {Boolean(errors.prereqPaymentMethod) &&
                                        touched.prereqPaymentMethod && (
                                            <RadioErrorMessage>
                                                {errors.prereqPaymentMethod}
                                            </RadioErrorMessage>
                                        )}
                                </FormControl>
                            )}
                        </TextFieldContainer>
                    )}
                </Row>
            </DiscountSection>
        </Paper>
    );
};
