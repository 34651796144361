import { LINKS_COLLECTION } from "../../screens/view-links/config/typesense.config.adapter";
import {
    ILinkView,
    LinkViewSortAttribute,
    TypesenseNumericFilterType,
    TypesenseSortOrder,
} from "../types";

export interface ILinkViewChangeEvent {
    id: string;
    value: ILinkView;
    event: "create" | "update" | "delete" | "filter-change" | "reset";
}

export interface IFilterListItem {
    attribute: string;
    value: any;
    label: string;
}

export interface IUpdateViewParams {
    view: ILinkView;
    attribute: string;
    value: any;
}

export interface IRemoveFilterParams {
    linkView: ILinkView;
    attribute: string;
    value: any;
}

export interface IGetLinkViewTypesenseUiStateProps {
    view: ILinkView;
}

export interface ILinkViewTypesenseUiState {
    refinementList: Record<string, string[]>;
    sortBy: string;
    range: Record<string, string>;
}

export class LinkViewUtils {
    public static getFilterList(
        filters: ILinkView["filters"],
    ): IFilterListItem[] {
        const list: IFilterListItem[] = [];

        const { createdAt, clicks, scans, tags, sort } = filters;

        if (createdAt.minDate || createdAt.maxDate) {
            if (createdAt.minDate && createdAt.maxDate) {
                list.push({
                    label: `CreatedAt: from ${createdAt.minDate.toLocaleDateString()} till ${createdAt.maxDate.toLocaleDateString()}`,
                    value: {
                        min: createdAt.minDate,
                        max: createdAt.maxDate,
                    },
                    attribute: "createdAt",
                });
            } else {
                const date = createdAt.minDate
                    ? createdAt.minDate.toLocaleDateString()
                    : createdAt.maxDate?.toLocaleDateString();
                list.push({
                    label: createdAt.minDate
                        ? `CreatedAt: from ${date}`
                        : `CreatedAt: till ${date}`,
                    value: {
                        min: createdAt.minDate,
                        max: createdAt.maxDate,
                    },
                    attribute: "createdAt",
                });
            }
        }

        if (
            (clicks.mode === TypesenseNumericFilterType.EXACT_VALUE &&
                clicks.exactValue) ||
            (clicks.mode === TypesenseNumericFilterType.RANGE &&
                (clicks.range.min || clicks.range.max))
        ) {
            if (clicks.mode === TypesenseNumericFilterType.EXACT_VALUE) {
                list.push({
                    label: `Clicks: ${clicks.exactValue}`,
                    value: {
                        exactValue: clicks.exactValue,
                    },
                    attribute: "clicks",
                });
            } else {
                if (clicks.range.min && clicks.range.max) {
                    list.push({
                        label: `${clicks.range.min} <= Clicks <= ${clicks.range.max}`,
                        value: {
                            min: clicks.range.min,
                            max: clicks.range.max,
                        },
                        attribute: "clicks",
                    });
                } else {
                    if (clicks.range.min) {
                        list.push({
                            label: `Clicks >= ${clicks.range.min}`,
                            value: {
                                min: clicks.range.min,
                            },
                            attribute: "clicks",
                        });
                    }
                    if (clicks.range.max) {
                        list.push({
                            label: `Clicks <= ${clicks.range.max}`,
                            value: {
                                max: clicks.range.max,
                            },
                            attribute: "clicks",
                        });
                    }
                }
            }
        }

        if (
            (scans.mode === TypesenseNumericFilterType.EXACT_VALUE &&
                scans.exactValue) ||
            (scans.mode === TypesenseNumericFilterType.RANGE &&
                (scans.range.min || scans.range.max))
        ) {
            if (scans.mode === TypesenseNumericFilterType.EXACT_VALUE) {
                list.push({
                    label: `Scans: ${scans.exactValue}`,
                    value: {
                        exactValue: scans.exactValue,
                    },
                    attribute: "scans",
                });
            } else {
                if (scans.range.min && scans.range.max) {
                    list.push({
                        label: `${scans.range.min} <= Scans <= ${scans.range.max}`,
                        value: {
                            min: scans.range.min,
                            max: scans.range.max,
                        },
                        attribute: "scans",
                    });
                } else {
                    if (scans.range.min) {
                        list.push({
                            label: `Scans >= ${scans.range.min}`,
                            value: {
                                min: scans.range.min,
                            },
                            attribute: "scans",
                        });
                    }
                    if (scans.range.max) {
                        list.push({
                            label: `Scans <= ${scans.range.max}`,
                            value: {
                                max: scans.range.max,
                            },
                            attribute: "scans",
                        });
                    }
                }
            }
        }

        if (tags.length > 0) {
            const tagsFilter: IFilterListItem[] = tags.map(
                (tag: string): IFilterListItem => ({
                    attribute: "tags",
                    value: tag,
                    label: `Tag: ${tag}`,
                }),
            );
            list.push(...tagsFilter);
        }

        if (sort.attribute) {
            list.push({
                label: `Sort by: ${this.getSortAttributeValue(
                    sort.attribute,
                )} (${
                    sort.order === TypesenseSortOrder.ASCENDING ? "asc" : "desc"
                })`,
                value: sort,
                attribute: "sort",
            });
        }

        return list;
    }

    public static clearAllFilters(linkView: ILinkView): ILinkView {
        return {
            ...linkView,
            filters: {
                createdAt: {
                    minDate: null,
                    maxDate: null,
                },
                clicks: {
                    mode: null,
                    exactValue: null,
                    range: {
                        min: null,
                        max: null,
                    },
                },
                scans: {
                    mode: null,
                    exactValue: null,
                    range: {
                        min: null,
                        max: null,
                    },
                },
                tags: [],
                sort: {
                    attribute: null,
                    order: null,
                },
            },
        };
    }

    public static getLinkFiltersFromList(
        filterList: IFilterListItem[],
    ): ILinkView["filters"] {
        const filters: ILinkView["filters"] = {
            createdAt: {
                minDate: null,
                maxDate: null,
            },
            clicks: {
                mode: null,
                exactValue: null,
                range: {
                    min: null,
                    max: null,
                },
            },
            scans: {
                mode: null,
                exactValue: null,
                range: {
                    min: null,
                    max: null,
                },
            },
            tags: [],
            sort: {
                attribute: null,
                order: null,
            },
        };

        filterList.forEach((filterItem) => {
            switch (filterItem.attribute) {
                case "createdAt":
                    if (filterItem.value.minDate && filterItem.value.maxDate) {
                        filters.createdAt.minDate = new Date(
                            filterItem.value.minDate,
                        );
                        filters.createdAt.maxDate = new Date(
                            filterItem.value.maxDate,
                        );
                    } else {
                        const date = new Date(
                            filterItem.value.min || filterItem.value.max,
                        );
                        if (filterItem.value.min) {
                            filters.createdAt.minDate = date;
                        } else {
                            filters.createdAt.maxDate = date;
                        }
                    }
                    break;
                case "clicks":
                    if (
                        filterItem.value.mode ===
                        TypesenseNumericFilterType.EXACT_VALUE
                    ) {
                        filters.clicks.mode =
                            TypesenseNumericFilterType.EXACT_VALUE;
                        filters.clicks.exactValue = filterItem.value.exactValue;
                    } else if (
                        filterItem.value.mode ===
                        TypesenseNumericFilterType.RANGE
                    ) {
                        filters.clicks.mode = TypesenseNumericFilterType.RANGE;
                        filters.clicks.range.min =
                            filterItem.value.range?.min ?? null;
                        filters.clicks.range.max =
                            filterItem.value.range?.max ?? null;
                    }
                    break;
                case "scans":
                    if (
                        filterItem.value.mode ===
                        TypesenseNumericFilterType.EXACT_VALUE
                    ) {
                        filters.scans.mode =
                            TypesenseNumericFilterType.EXACT_VALUE;
                        filters.scans.exactValue = filterItem.value.exactValue;
                    } else if (
                        filterItem.value.mode ===
                        TypesenseNumericFilterType.RANGE
                    ) {
                        filters.scans.mode = TypesenseNumericFilterType.RANGE;
                        filters.scans.range.min =
                            filterItem.value.range?.min ?? null;
                        filters.scans.range.max =
                            filterItem.value.range?.max ?? null;
                    }
                    break;
                case "tags":
                    filters.tags.push(filterItem.value);
                    break;
                case "sort":
                    filters.sort.attribute = filterItem.value.attribute;
                    filters.sort.order = filterItem.value.order;
                    break;
                default:
                    break;
            }
        });

        return filters;
    }

    public static updateView(params: IUpdateViewParams): ILinkView {
        const { view, attribute, value } = params;
        return {
            ...view,
            filters: { ...view.filters, [attribute]: value },
        };
    }

    public static removeFilter(params: IRemoveFilterParams): ILinkView {
        const { linkView, value, attribute } = params;

        const updatedFilters = { ...linkView.filters };
        switch (attribute) {
            case "tags":
                updatedFilters.tags = updatedFilters.tags.filter(
                    (tag: string) => tag !== value,
                );
                break;
            case "createdAt":
                updatedFilters.createdAt = { minDate: null, maxDate: null };
                break;
            case "clicks":
            case "scans":
                updatedFilters[attribute] = {
                    mode: null,
                    exactValue: null,
                    range: { min: null, max: null },
                };
                break;
            case "sort":
                updatedFilters.sort = {
                    attribute: null,
                    order: null,
                };
                break;
            default:
                break;
        }
        const updatedFilter = { ...linkView, filters: updatedFilters };
        return updatedFilter;
    }

    public static getSortAttributeValue(
        attribute: LinkViewSortAttribute | null,
    ) {
        switch (attribute) {
            case LinkViewSortAttribute.ALIAS: {
                return "alias";
            }

            case LinkViewSortAttribute.CLICKS: {
                return "clicks";
            }

            case LinkViewSortAttribute.SCANS: {
                return "scans";
            }

            case LinkViewSortAttribute.CREATED_AT: {
                return "createdAt";
            }

            default: {
                return null;
            }
        }
    }

    public static getSortAttributeEnum(
        value: string | null,
    ): LinkViewSortAttribute | null {
        switch (value) {
            case "alias": {
                return LinkViewSortAttribute.ALIAS;
            }

            case "clicks": {
                return LinkViewSortAttribute.CLICKS;
            }

            case "scans": {
                return LinkViewSortAttribute.SCANS;
            }

            case "createdAt": {
                return LinkViewSortAttribute.CREATED_AT;
            }

            default: {
                return null;
            }
        }
    }

    public static getTypesenseUiState(
        params: IGetLinkViewTypesenseUiStateProps,
    ): ILinkViewTypesenseUiState {
        const { view } = params;
        const { filters } = view;
        return {
            refinementList: {
                tags: filters.tags,
            },
            range: {
                clicks:
                    filters.clicks.mode ===
                    TypesenseNumericFilterType.EXACT_VALUE
                        ? `${filters.clicks.exactValue ?? 0}:${
                              filters.clicks.exactValue ?? 1 << 30
                          }`
                        : `${filters.clicks.range.min ?? 0}:${
                              filters.clicks.range.max ?? 1 << 30
                          }`,
                scans:
                    filters.scans.mode ===
                    TypesenseNumericFilterType.EXACT_VALUE
                        ? `${filters.scans.exactValue ?? 0}:${
                              filters.scans.exactValue ?? 1 << 30
                          }`
                        : `${filters.scans.range.min ?? 0}:${
                              filters.scans.range.max ?? 1 << 30
                          }`,
                createdAt: `${filters.createdAt.minDate?.getTime() ?? 0}:${
                    filters.createdAt.maxDate?.getTime() ??
                    Number.MAX_SAFE_INTEGER
                }`,
            },
            sortBy: `${LINKS_COLLECTION}/sort/${
                LinkViewUtils.getSortAttributeValue(filters.sort.attribute) ??
                "createdAt"
            }:${
                filters.sort.order === TypesenseSortOrder.ASCENDING
                    ? "asc"
                    : "desc"
            }`,
        };
    }
}
