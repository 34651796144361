import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import { Button, styled } from "@mui/material";

import { useClearRefinements } from "react-instantsearch";

import { FilterChip } from "../../../components/common/typesense";
import { ILinkView } from "../../../sdk/types";
import { IFilterListItem, LinkViewUtils } from "../../../sdk/utils";

const StyledFilterContainer = styled("div")`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    gap: ${({ theme }) => theme.spacing(1)};
`;

export interface IFilterListProps {
    view: ILinkView;
    showClearAll?: boolean;
    onChange: (view: ILinkView) => void;
}

export const FilterList: FunctionComponent<IFilterListProps> = (
    props: IFilterListProps,
): ReactElement => {
    const { view, onChange, showClearAll } = props;

    const [filterList, setFilterList] = useState<IFilterListItem[]>([]);

    const { refine } = useClearRefinements();

    const handleDelete = useCallback(
        (attribute: string, value: unknown) => {
            onChange(
                LinkViewUtils.removeFilter({
                    linkView: view,
                    attribute,
                    value,
                }),
            );
        },
        [onChange, view],
    );

    const handleClearAll = useCallback(() => {
        refine();
        onChange(LinkViewUtils.clearAllFilters(view));
    }, [refine, onChange, view]);

    useEffect(() => {
        setFilterList(LinkViewUtils.getFilterList(view.filters));
    }, [JSON.stringify(view)]);

    return (
        <StyledFilterContainer>
            {filterList.map((filterItem, index) => (
                <FilterChip
                    key={index}
                    {...filterItem}
                    onDelete={handleDelete}
                />
            ))}
            {filterList.length > 0 && showClearAll && (
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleClearAll}
                >
                    Clear All
                </Button>
            )}
        </StyledFilterContainer>
    );
};
