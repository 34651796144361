import { AxiosError } from "axios";
import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { ILinkView } from "../../types";
import { api } from "../../utils";
import { ICreateLinkViewParams } from "../../utils/api";
import { useAuth } from "../useAuth";

export interface ICreateLinkViewMutationParameters
    extends Omit<ICreateLinkViewParams, "token"> {}

/**
 * @author Nikhil babar <nikhil.babar@houseofx.in>
 */
export const useCreateLinkView = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        ILinkView,
        AxiosError,
        ICreateLinkViewMutationParameters,
        unknown
    >,
): UseMutationResult<
    ILinkView,
    AxiosError,
    ICreateLinkViewMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        ILinkView,
        AxiosError,
        ICreateLinkViewMutationParameters
    >({
        ...options,
        mutationFn: (
            parameters: ICreateLinkViewMutationParameters,
        ): Promise<ILinkView> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create link-view without authorization token.",
                );
            }

            return api.LinkView.create({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: (
            linkView: ILinkView,
            variables: ICreateLinkViewMutationParameters,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(linkView, variables, context);
        },
    });
};
