import { FunctionComponent, ReactElement, useCallback } from "react";

import { Chip, styled } from "@mui/material";

const StyledChip = styled(Chip)`
    padding: ${({ theme }) => theme.spacing(0, 1)};
    margin: ${({ theme }) => theme.spacing(0.5)};
`;

export interface ITagItem {
    count: number;
    value: string;
    label: string;
}

export interface ITagChipProps {
    item: ITagItem;
    checked: boolean;
    onToggle: (attribute: string) => void;
}

export const TagChip: FunctionComponent<ITagChipProps> = (
    props: ITagChipProps,
): ReactElement => {
    const { item, checked, onToggle } = props;

    const handleToggle = useCallback(() => {
        onToggle(item.value);
    }, [onToggle, item]);

    return (
        <StyledChip
            icon={<div>{item.count}</div>}
            key={item.value}
            label={item.label}
            color={checked ? "info" : "default"}
            clickable={true}
            onClick={handleToggle}
        />
    );
};
