import {
    AdminRole,
    AdminStatus,
    CustomerSelection,
    Department,
    DiscountCodeStatus,
    DiscountType,
    OrderConfirmationSource,
    OrderStatus,
    PaymentMethodType,
    PriceRuleCategoryType,
    PriceRuleStatus,
    ProductStatus,
    RefundPlatformType,
    RefundReason,
    RefundStatusType,
} from "../../utils/enums";

import {
    LinkViewSortAttribute,
    TypesenseNumericFilterType,
    TypesenseSortOrder,
} from "./enums";

export enum PriceRuleAllocationMethod {
    LOWEST_LINE_ITEMS = 1,
    HIGHEST_LINE_ITEMS = 2,
    COMBINED_LINE_ITEMS = 3,
    EACH_LINE_ITEM = 4,
}

export enum PriceRuleCustomerSelection {
    ALL = 1,
    PREREQ = 2,
}

export enum PriceRuleDiscountType {
    FIXED = 1,
    PERCENTAGE = 2,
}

export enum CustomerReviewStatus {
    APPROVED = 1,
    REJECTED = 2,
    PENDING = 3,
}

export const CustomerReviewStatusMap: Record<number, string> = {
    [CustomerReviewStatus.APPROVED]: "Approved",
    [CustomerReviewStatus.REJECTED]: "Rejected",
    [CustomerReviewStatus.PENDING]: "Pending",
};

export interface IExternalListPage<T> {
    records: T[];
    cursors: {
        next?: string;
        previous?: string;
    };
}

export interface IAdmin {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    status: AdminStatus;
    department: Department;
    role: AdminRole;
    emailAddress: string;
    firstName: string;
    lastName: string;
    pictureUrl: string;
}

export interface IInvitation {
    invitationUrl: string;
    inviteeId: string;
    inviterId: string;
}

export interface IClientAttributes {
    acceptLanguage: string;
    height: number;
    width: number;
    userAgent: string;
    customerId: string | undefined;
}

export interface ILink {
    id: string;
    name: string;
    description: string;
    startsAt: Date | null;
    endsAt: Date | null;
    destination: string;
    alias: string;
    allowIab: boolean;
    tags: string;
    clicks: number;
    scans: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface ILinkView {
    id: string;
    label: string;
    editable: boolean;
    filters: {
        createdAt: {
            minDate: Date | null;
            maxDate: Date | null;
        };
        clicks: {
            mode: TypesenseNumericFilterType | null;
            range: {
                min: number | null;
                max: number | null;
            };
            exactValue: number | null;
        };
        scans: {
            mode: TypesenseNumericFilterType | null;
            range: {
                min: number | null;
                max: number | null;
            };
            exactValue: number | null;
        };
        tags: string[];
        sort: {
            attribute: LinkViewSortAttribute | null;
            order: TypesenseSortOrder | null;
        };
    };
    createdAt: Date;
    updatedAt: Date;
}

export interface ICustomer {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    emailAddressVerified: boolean;
    phoneNumber: string;
    phoneNumberVerified: boolean;
    pictureURL: string;
    birthday: Date | null;
    gender: string | null;
    country: string | null;
    currency: string;
    emailMarketingConsent: boolean;
    emailMarketingConsentUpdatedAt: Date;
    smsMarketingConsent: boolean;
    smsMarketingConsentUpdatedAt: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICustomerAddress {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    countryCode: string;
    company: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    alternatePhoneNumber: string;
    emailAddress: string;
    stateCode: string;
    zip: string;
    name: string;
    customerId: string;
    defaultAddress: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface IPincodeMapping {
    pincode: number;
    district: string;
    state: string;
    region: string;
    deliveryStatus: number;
}

export interface IJwtSession {
    jwtToken: string;
    admin: IAdmin;
    createdAt: Date;
}

export interface IMessage {
    message: string;
}

export interface IOrderLineItem {
    id: string;
    price: number;
    mrp: number;
    variantId: string;
    orderId: string;
    quantity: number;
    createdAt: Date;
    updatedAt: Date;
    reference?: string;
}

export interface IOrder {
    id: string;
    storeId: string;
    billingCustomerAddressId: string;
    status: OrderStatus;
    number: number;
    name: string;
    note: string | null;
    shippingCustomerAddressId: string;
    test: boolean;
    closedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface IRefundOrderResponse {
    message: string;
}

export interface IProductImage {
    objectUrl: string;
    position: number;
    title: string;
}

export interface IProductVariant {
    id: string;
    title: string;
    shopifyVariantId: string;
    price: number;
    shippingPrice: number;
    mrp: number;
    sku: string;
    position: number;
    productId: string;
    status: ProductStatus;
    images: IProductImage[];
}

export interface IProduct {
    id: string;
    title: string;
    description: string;
    tagLine: string;
    slug: string;
    featureScore: number;
    bestSellerScore: number;
    shopifyProductId: string;
    variants: IProductVariant[];
    status: ProductStatus;
    meta: any;
    schemaId?: string;
    value?: Record<any, any>;
}

export interface ICartItem {
    id: string;
    variantId: string;
    imageUrl: string;
    title: string;
    quantity: number;
    price: {
        amount: number;
        currencyCode: string;
    };
}

export interface IDiscount {
    id: string;
    storeId: string;
    code: string;
    createdAt: Date;
    updatedAt: Date;
    priceRuleId: string;
}

export interface IPriceRule {
    id: string;
    name: string;
    description: string;
    notes: string | null;
    status: PriceRuleStatus;
    category: PriceRuleCategoryType;
    prereqPaymentMethod: PaymentMethodType;
    prereqVariantsRequired: number;
    variantIds: string[];
    prereqVariants: IPrereqPriceRuleVariant[];
    allocationMethod: PriceRuleAllocationMethod;
    customerSelection: CustomerSelection;
    prereqCustomerGroupIds: string[];
    discountType: DiscountType;
    startsAt: Date;
    endsAt: Date | null;
    usageLimitByCustomer: number;
    usageLimitByTotal: number;
    discountValue: number;
    prereqQuantityMin: number;
    prereqSubtotalMin: number;
    maxDiscountAmount: number | null;
}

export interface IPrereqPriceRuleVariant {
    /**
     * UUID v4 string that uniquely identifies the record using binary.
     */
    id: string;

    /**
     * UUID v4 string that uniquely identifies the associated price rule.
     */
    priceRuleId: string;

    /**
     * UUID v4 string that uniquely identifies the associated variant.
     */
    variantId: string;

    /**
     * The Shopify GID that points to the product variant.
     */
    shopifyVariantId: string;
}

export interface IDiscountCode {
    code: string;
    status: DiscountCodeStatus;
    createdBy: IAdmin;
    id: string;
}

export interface IPriceRuleResponse extends IPriceRule {
    discountCodes: IDiscountCode[];
    createdAt: Date;
    updatedAt: Date;
    createdBy: IAdmin;
}

export interface ICustomerGroupMapping {
    id: string;
    phoneNumber: string;
    customerGroupId: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICustomerGroup {
    id: string;
    name: string;
    description: string;
    customerGroupMapping: ICustomerGroupMapping[];
    customerIds?: string[];
    createdAt: Date;
    updatedAt: Date;
}

export interface IPayment {
    id: string;
    status: string;
    test: boolean;
    amount: number;
    gateway: string;
    orderId: string;
    paymentMethodType: PaymentMethodType;
    shadowId: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IAppliedPriceRule {
    id: string;
    name: string;
    description: string;
    allocationMethod: PriceRuleAllocationMethod;
    discountType: PriceRuleDiscountType;
    discountValue: number;
    discountAmount: number;
    orderId: string;
    priceRuleId: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
    discountCode: string;
    version: number;
}

export interface BasePaymentLink {
    type: "juspay" | "bharat_x";
}

export interface IRefund {
    id: string;
    status: RefundStatusType;
    paymentId: string;
    amount: number;
    platform: RefundPlatformType;
    reason: RefundReason;
    note: string;
    createdAt: Date;
}

export interface IRefundHistory {
    nativeStatus: number;
    refundId: string;
    platform: RefundPlatformType;
    createdAt: Date;
}

export interface IJuspayPaymentSession extends BasePaymentLink {
    type: "juspay";
    status: string;
    id: string;
    order_id: string;
    payment_links: {
        web: string;
    };
    sdk_payload: {
        requestId: string;
        service: string;
        payload: {
            clientId: string;
            amount: number;
            merchantId: string;
            clientAuthToken: string;
            clientAuthTokenExpiry: string;
            environment: string;
            lastName: string;
            action: string;
            customerId: string;
            returnUrl: string;
            currency: string;
            firstName: string;
            customerPhone: string;
            customerEmail: string;
            orderId: string;
            description: string;
        };
    };
}

export interface IOrderV1_1 {
    id: string;
    storeId: string;
    status: OrderStatus;
    number: number;
    name: string;
    note: string | null;
    test: boolean;
    closedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    payment: IPayment;
    discountAmount: number;
    subtotalAmount: number;
    remainingAmount: number;
    shippingAmount: number;
    orderLineItems: IOrderLineItem[];
    shippingCustomerAddress: ICustomerAddress;
    billingCustomerAddress: ICustomerAddress;
    customer: ICustomer;
    appliedPriceRules?: IAppliedPriceRule[];
    cartId?: string;
    confirmationSource: OrderConfirmationSource | null;
}

export interface IGetOrderResponse extends IOrderV1_1 {
    sellerId: string | null;
    actualAmount: number;
    seller?: IAdmin;
    refunds?: IRefund[];
    refundHistories?: IRefundHistory[];
}

export interface ICreateOrderResponse extends IOrderV1_1 {
    paymentSession: IJuspayPaymentSession;
}

export interface IProduct {
    title: string;
    description: string;
    tagLine: string;
    featureScore: number;
    bestSellerScore: number;
    slug: string;
    shopifyProductId: string;
    variants: IProductVariant[];
    images: IProductImage[];
    position: number;
}

export interface IProductCollectionEntry {
    title: string;
    description: string;
    tagLine: string;
    featureScore: number;
    bestSellerScore: number;
    slug: string;
    shopifyProductId: string;
    variant: IProductVariant;
    images: IProductImage[];
    position: number;
    manualScore: number;
}

export interface ICollection {
    id: string;
    title: string;
    description: string;
    slug: string;
    TypesenseSortOrder: number;
    createdAt: Date;
    updatedAt: Date;
    products: IProductCollectionEntry[];
}

export interface ICustomerReviewAsset {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    customerReviewId: string;
    objectKey: string;
}

export interface IProductVariantDetail extends IProductVariant {
    images: IProductImage[];
    product: IProduct;
}

export interface IAdminOrder {
    id: string;
    status: OrderStatus;
    number: number;
    name: string;
    note: string | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICustomerReviewDistribution {
    count: number[];
    average: number[];
}
export interface ICustomerReviewMetrics {
    distribution: ICustomerReviewDistribution;
    total: number;
    average: number;
}
export interface ICustomerReviewDetails {
    reviews: IExternalListPage<ICustomerReview>;
    metrics: ICustomerReviewMetrics;
}

export interface ICustomerReviewModeration {
    assignedBy: string | null;
    assignedTo: string | null;
    comment: string | null;
    flaggedAt: Date;
    flaggedReason: number;
    id: string;
    moderatedBy: string | null;
    reviewId: string;
    status: number;
    updatedAt: Date;
}

export interface ICustomerReview {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    rating: number;
    content: string | null;
    status: number;
    sentiment?: number;
    variantId: string;
    customerId: string;
    customer: ICustomer;
    assets: ICustomerReviewAsset[];
    admin?: IAdmin;
    order: IAdminOrder | undefined;
    variant: IProductVariantDetail;
}

export interface IAdminGroup {
    id: string;
    name: string;
    description: string;
    admins: IAdmin[];
}

export interface ICartLineItem {
    id: string;
    variantId: string;
    quantity: number;
    price: number;
    mrp: number;
    shippingPrice: number;
    imageUrl: string;
    title: string;
    sku: string;
    productId: string;
    createdAt: Date;
    updatedAt: Date;
}

export enum PriceRuleValidityType {
    PREREQ_SUBTOTAL_MIN = "prereq_subtotal_min",
    EXPIRATION = "expiration",
    PAYMENT_METHOD = "payment_method",
    // ENTITLED_VARIANTS = "entitled_variants",
    PREREQ_VARIANTS = "prereq_variants",
}

export interface IPriceRuleValidity {
    type: PriceRuleValidityType;
    valid: boolean;
}

export interface IAnnotatedPriceRule {
    id: string;
    name: string;
    description: string;
    discounts: IDiscountCode[];
    storeId: string;
    allocationMethod: PriceRuleAllocationMethod;
    customerSelection: PriceRuleCustomerSelection;
    discountType: PriceRuleDiscountType;
    startsAt: Date;
    endsAt: Date;
    oncePerCustomer: boolean;
    usageLimit: number;
    discountValue: number;
    prereqQuantityMin: number;
    prereqSubtotalMin: number;
    prereqPaymentMethod: PaymentMethodType;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    category: number;
    prereqVariantsRequired: number;
    prereqVariants: IPrereqPriceRuleVariant[];
    validities: IPriceRuleValidity[];
    applicable: boolean;
}

export interface ICartPaymentRestriction {
    id: string;
    description: string;
    maxAmount: number;
    minAmount: number;
    paymentMethod: PaymentMethodType;
    createdAt: Date;
    updatedAt: Date;
}

export interface IAppliedCartPriceRule {
    id: string;
    name: string;
    description: string;
    category: number;
    allocationMethod: PriceRuleAllocationMethod;
    discountType: PriceRuleDiscountType;
    discountValue: number;
    discountAmount: number;
    orderId: string;
    priceRuleId: string;
    createdAt: Date;
    updatedAt: Date;
    discountCode: string;
    version: number;
}

export interface IOrderBreakdown {
    quantity: number;
    discountAmount: number;
    shopifyVariantId: string;
}

export interface IDiscountCodeWithDescription {
    id: string;
    code: string;
    description: string;
}

export interface ICart {
    id: string;
    customerId: string;
    customer?: ICustomer;
    sellerId: string;
    items: ICartLineItem[];
    paymentRestrictions?: ICartPaymentRestriction[];
    paymentMethodType: string;
    totalQuantity: number;
    totalSellingPrice: number;
    totalMrp: number;
    totalDiscountAmount: number;
    publicAnnotatedPriceRules: IAnnotatedPriceRule[];
    appliedCartPriceRules: IAppliedCartPriceRule[];
    applicableDiscountCodes: IDiscountCodeWithDescription[];
    createdAt: Date;
    updatedAt: Date;
    annotation?: IAnnotatedPriceRule;
    breakdown: IOrderBreakdown[];
}

export interface IBulkOrder {
    id: string;
    country: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    scheduledCallDate: string;
    scheduledCallEndTime: string;
    scheduledCallStartTime: string;
    state: string;
    phoneNumber: string;
    totalSellingPrice: number;
    totalAskingPrice: number;
    reason: string;
    createdAt: Date;
}

export interface IBulkOrderLineItem {
    askingPrice: number;
    bulkOrderId: string;
    createdAt: string;
    id: string;
    quantity: number;
    sellingPrice: number;
    variantId: string;
}

export interface IBulkOrderResponse {
    bulkOrder: IBulkOrder;
    bulkOrderLineItems: IBulkOrderLineItem[];
}
