import {
    FunctionComponent,
    MouseEvent,
    ReactElement,
    useCallback,
} from "react";

import { MenuItem } from "@mui/material";

export interface IFacetMenuItem {
    value: string;
    label: string;
    onClick: (event: MouseEvent<HTMLLIElement>, value: string) => void;
}

export const FacetMenuItem: FunctionComponent<IFacetMenuItem> = (
    props: IFacetMenuItem,
): ReactElement => {
    const { value, label, onClick } = props;

    const handleClick = useCallback(
        (event: MouseEvent<HTMLLIElement>) => {
            onClick(event, value);
        },
        [value, onClick],
    );

    return (
        <MenuItem value={value} onClick={handleClick}>
            {label}
        </MenuItem>
    );
};
