import {
    ChangeEvent,
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import { TextField, Typography, styled } from "@mui/material";

import { useRefinementList } from "react-instantsearch";

import { TagChip } from "./TagChip";

const Root = styled("div")``;

const InputField = styled(TextField)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export interface ITagInputSearchBoxProps {
    attribute: string;
    tags: string[];
    onChange: (value: string[]) => void;
}

export const TagInputSearchBox: FunctionComponent<ITagInputSearchBoxProps> = (
    props: ITagInputSearchBoxProps,
): ReactElement => {
    const { attribute, tags, onChange } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");

    const { items, searchForItems } = useRefinementList({
        attribute: attribute,
    });

    const handleValueChange = useCallback(
        (value: string) => {
            const cloneSet = new Set(tags);
            if (cloneSet.has(value)) {
                cloneSet.delete(value);
            } else {
                cloneSet.add(value);
            }
            onChange([...cloneSet.values()]);
        },
        [tags],
    );

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        searchForItems(event.target.value);
        setSearchTerm(event.target.value);
    }, []);

    return (
        <Root>
            <InputField
                onChange={handleSearch}
                size="small"
                label={"Search"}
                type="text"
                value={searchTerm}
            />
            {items &&
                items.length > 0 &&
                items.map((item) => (
                    <TagChip
                        item={item}
                        onToggle={handleValueChange}
                        key={item.value}
                        checked={tags.includes(item.value)}
                    />
                ))}
            {!items ||
                (items.length === 0 && (
                    <Typography>No tags available</Typography>
                ))}
        </Root>
    );
};
