import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { useAuth } from "..";
import { ILinkView } from "../../types";
import { api } from "../../utils";
import { IListLinkViewParams } from "../../utils/api";

export type TUseListAllLinkViewsQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn">;

export interface IUseListAllLinkViewsResult {
    records: ILinkView[];
}

export interface IUseListAllLinkViewsParams {
    params: Omit<IListLinkViewParams, "token">;
    options?: TUseListAllLinkViewsQueryOptions<
        any,
        unknown,
        IUseListAllLinkViewsResult,
        string[]
    >;
}

export const useListAllLinkViews = (
    parameters: IUseListAllLinkViewsParams,
): UseQueryResult<IUseListAllLinkViewsResult, unknown> => {
    const { options, params } = parameters;
    const auth = useAuth();

    return useQuery({
        ...options,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch links without authorization token.",
                );
            }

            return await api.LinkView.listAll({
                ...params,
                token: auth.token,
            });
        },
    });
};
